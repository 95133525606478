import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import HomePage1 from "../../assets/images/homepage1.jpg";
import HomePage2 from "../../assets/images/homepage2.jpg";
import WhyWeHere from "./whyWeHere";
import Process from "./process/process";
import KeyFeatures from "./keyFeatures/keyFeatures";
import PopularCourses from "../../components/popularCourses/popularCoursesCards";
import Testimonials from "../../components/testimonials/testimonial";
import QueryForm from "./querySection/queryFormSection";
import QueryImage from "./querySection/queryImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactEventTracker from "../../hooks/react-ga";
import { faArrowRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./homeStyles.css";
function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

const gaEventTracker = useReactEventTracker("User-Interaction-Home-Page");

  return (
    <>
      <Helmet>
        <title>Home | Rajala's Tech Academy</title>
        <meta
          name="description"
          content="An Online education platform providing 500+ courses offerings which improves your skill set and advance your career with new learning. Join us today"
        />
        <meta
          name="keywords"
          content="Online Education, Online Courses, Online tutors, Coaching platform, online education in india, Learning destination, e-Learning,
          distance education, online certificate courses 2020, distance learning, best online courses 2020, online training, how to start, where to begin with, online classes, training hub,technical training"
        />
      </Helmet>

      <section className="oep-home-section first-banner">
        <div
          id="home-main"
          className="carousel slide"
          data-ride="carousel"
          data-interval="5000"
          data-pause="false"
        >
          <div className="carousel-inner">
            <div
              className="carousel-item active homepg-bg"
              style={{
                backgroundImage: `url('${HomePage1}')`,
              }}
            >
              <div className="overlay-bg">
                <h1 className="main-title">
                  Your career is in safe hands when you study with us.
                </h1>
              </div>
            </div>
            <div
              className="carousel-item homepg-bg"
              style={{ backgroundImage: `url('${HomePage2}')` }}
            >
              <div className="overlay-bg">
                <h1 className="main-title">
                  We work hard and smart. Just as you do.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="abs-parent">
          <Link
            to="/courses"
            className="main-content-button"
            id="home-main-content-btn"
          >
            <span onClick={()=>{
               gaEventTracker("Home Interested Button Clicked by the User");
            }}>Interested ?</span>
          </Link>
          <div className="scroll-down">
            <button className="scroll-down-btn" title="scroll down">
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
            {/* <small>scroll down</small> */}
          </div>
        </div>
      </section>
      <section
        className="oep-home-section why-we-here"
        id="home-whywehere-section"
      >
        <WhyWeHere />
      </section>

      <section className="oep-home-section process" id="home-process-section">
        <Process />
      </section>
      <section
        className="key-features oep-home-section"
        id="home-keyfeatures-section"
      >
        <KeyFeatures />
      </section>

      <section className="oep-home-section pop-courses">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="pop-title-container">
                <h1
                  className="pop-course-title"
                  id="home-popular-courses-title"
                >
                  Popular Courses
                </h1>
                <p
                  className="pop-course-info"
                  id="home-popular-courses-subheader"
                >
                  Find out the popular courses offered here.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9 d-flex align-items-stretch flex-column mb-5 mb-lg-0 home-popular-courses">
              <PopularCourses />
              <Link
                to="/courses"
                className="pop-course-loadmore"
                id="home-popular-courses-loadmore-btn"
              >
                Load More <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="oep-home-section testimonials">
        <div className="container" id="home-testimonials-content">
          <Testimonials />
        </div>
      </section>
      <section className="oep-home-section" id="query-section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-xl-6 col-md-12 col-sm-12"
              id="home-queryform-section"
            >
              <QueryForm />
            </div>
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 pr-0">
              <QueryImage />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
