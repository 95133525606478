import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import StarRatings from "react-star-ratings";
import LinesEllipsis from "react-lines-ellipsis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import SkeletonListCard from "./loadingListCard/skeleton";
import "./listCard.css";

export default function ListCard(props) {
  const [courseData, setCourseData] = useState({
    course_id: "",
    thumbnail_url: "",
    course_name: "",
    rating: 0,
    summary: "",
  });
  const [isIE, setIsIE] = useState(false);
  const [maxLine, setMaxLine] = useState(3);
  let history = useHistory();
  const navigate = (id) => {
    //the id can be used later after implementing the course detail page.
    let url_coursename = courseData.course_name;
    url_coursename = url_coursename.replace(/\s+/g, "-").toLowerCase();

    history.push(`/courses/view/?courseName=${url_coursename}&id=${id}`);
  };

  function handleReSize() {
    setMaxLine(3);
  }

  useEffect(() => {
    if (props.data !== undefined) {
      let { data } = props;

      setCourseData(data);
    }
  }, [props]);
  useEffect(() => {
    function checkisIE() {
      let ua = navigator.userAgent;
      /* MSIE used to detect old browsers and Trident used to newer ones*/
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

      return is_ie;
    }
    setIsIE(checkisIE);
  }, []);

  useEffect(() => {
    if (isIE) {
      window.addEventListener("resize", handleReSize);
      return () => {
        window.removeEventListener("resize", handleReSize);
      };
    }
  });

  let { course_id, thumbnail_url, course_name, rating, summary } = courseData;
  return (
    <>
      {course_id !== "" ? (
        <article
          className="course-list-card container mx-auto"
          id="listcard-course-navigate"
          onClick={() => navigate(course_id)}
          tabIndex="-1"
          title={course_name}
        >
          <div className="thumbnail">
            {thumbnail_url !== "" && thumbnail_url !== undefined ? (
              <img
                src={thumbnail_url}
                alt={course_name}
                id="gridcard-course-image"
              />
            ) : (
              <div className="gray-bg">{""}</div>
            )}
          </div>

          <div className="course-details">
            {isIE ? (
              <LinesEllipsis
                text={course_name}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="words"
                className="course-title"
                id="listcard-course-title"
              />
            ) : (
              <h2 className="course-title" id="listcard-course-title">
                {course_name}
              </h2>
            )}

            <span className="course-card-rating">
              <span id="listcard-course-rating">
                {parseFloat(rating).toFixed(1)}
              </span>
              <StarRatings
                rating={parseFloat(rating)}
                starRatedColor="#ffca08"
                numberOfStars={5}
                name="rating"
                starDimension="18px"
              />
            </span>

            <div className="more-info mt-3 d-flex justify-content-center flex-column">
              {isIE ? (
                <LinesEllipsis
                  text={summary}
                  maxLine={maxLine}
                  ellipsis="..."
                  trimRight
                  basedOn="words"
                  className="course-summary"
                  id="listcard-course-summary"
                />
              ) : (
                <p className="course-summary" id="listcard-course-summary">
                  {summary}
                </p>
              )}
            </div>
          </div>
          <div className="view-more">
            <div
              className="view-more-arrow"
              id="listcard-course-view-more-arrow"
            >
              <FontAwesomeIcon icon={faLongArrowAltRight} size="1x" />
            </div>

            <span id="listcard-course-view-more">view more</span>
          </div>
        </article>
      ) : (
        <SkeletonListCard />
      )}
    </>
  );
}
