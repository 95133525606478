import React, { useEffect, useState, useContext, useRef } from "react";
import { ModalContext } from "../App";
import ModalView from "./modalView";
function ModalPopUp() {
  const [Popup, setPopUp] = useState(false);
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const [show, setShow] = useState(true);
  const modalRef = useRef(show);
  modalRef.current = show;
  useEffect(() => {
    let timer = () => {
      setTimeout(() => {
        if (modalRef.current) {
          setResponseModal({
            ...responseModal,
            queryModal: true,
          });
        }
      }, 25000);
    };
    if (show) {
      timer();
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPopUp(responseModal.queryModal);
    if (
      responseModal.beTutorModal === false &&
      responseModal.reqDemoModal === false &&
      responseModal.successModal === false &&
      responseModal.errorModal === false &&
      responseModal.termsModal === false &&
      responseModal.privacyModal === false
    ) {
      setShow(true);
      modalRef.current = true;
    } else {
      setShow(false);
      modalRef.current = false;
    }
  }, [responseModal]);

  const closePopup = () => {
    setResponseModal({
      ...responseModal,
      beTutorModal: false,
      reqDemoModal: false,
      queryModal: false,
      privacyModal: false,
      termsModal: false,
    });
  };
  return (
    <React.Fragment>
      {Popup ? (
        <ModalView type="modal-to-popup" show={Popup} onHide={closePopup} />
      ) : null}
    </React.Fragment>
  );
}
export default ModalPopUp;
