import React, { useState, Fragment } from "react";
import package_img from "../../../assets/images/package.png";
import schedule_img from "../../../assets/images/calendar.png";
import connectLive_img from "../../../assets/images/chat.png";
import materials_img from "../../../assets/images/book.png";
import ModalView from "../../../components/modalView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import useReactEventTracker from "../../../hooks/react-ga";
// import SmoothScroll from "smooth-scroll";
import "./processStyles.css";
function Process(props) {
  const processData = [
    {
      imgSrc: package_img,
      title: "Select a Course",
      description:
        "Choose one of our 500+ course offerings and improve your skill set and advance your career with new learning.",
    },
    {
      imgSrc: schedule_img,
      title: "Schedule a live demo session",
      description:
        "Book a free demo session at your convenient timing and share your learning requirements with the trainer.",
    },
    {
      imgSrc: connectLive_img,
      title: "Connect Live",
      description:
        "Start learning based on your schedule using smartphone or laptop anywhere, any time.  Get 100% practical experience and the trainers will guide you throughout the course",
    },
    {
      imgSrc: materials_img,
      title: "Get Project code and materials",
      description:
        "Get training project code, course materials and session recordings on successful course completion.",
    },
  ];
  const [modalShow, setModalShow] = useState(false);
  const gaEventTracker = useReactEventTracker("User-Interaction-Home-Page");
  // eslint-disable-next-line
  // 	var scroll = new SmoothScroll('a[href*="#"]', {
  //     // Speed & Duration
  //     speed: 700, // Integer. Amount of time in milliseconds it should take to scroll 1000px
  //     speedAsDuration: true, // If true, use speed as the total duration of the scroll animation
  //     durationMax: 1000, // Integer. The maximum amount of time the scroll animation should take
  //     durationMin: 300, // Integer. The minimum amount of time the scroll animation should take
  //     clip: true, // If true, adjust scroll distance to prevent abrupt stops near the bottom of the page
  //     easing: 'easeOutCubic', // Easing pattern to use
  //     updateURL: false, // Update the URL on scroll
  // });
  return (
    <div className="container">
      <div className="section-title-container d-flex justify-content-center mb-5">
        <h2 className="section-title">Process</h2>
      </div>
      <div className="row justify-content-center">
        {processData.map((process, index) => {
          return (
            <Fragment key={index}>
              <div
                id="home-process-card"
                className="col-md-6 col-xl-3 col-lg-4 d-flex align-items-stretch mb-2 mb-lg-0 process-card-container"
              >
                <Card data={process} step={index + 1} />
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className="d-flex">
        <div
          id="home-process-footer"
          className="query-model-section mx-auto mt-5 d-flex justify-content-around align-items-center"
        >
          <strong className="process-query-qstn">
            Have a doubt in the process?
          </strong>
          <a href="#query-section">
            <button
              className="process-query-btn"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              id="home-process-button"
              onClick={()=>{
                gaEventTracker("Home Feel-free-to-reach-us Button Clicked by the User");
              }}
            >
              Feel free to reach us. <FontAwesomeIcon icon={faSmile} />
            </button>
          </a>
        </div>
      </div>
      <ModalView show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

function Card(props) {
  return (
    <div className="process-card">
      <div className="process-step-badge">
        <strong>{props.step}</strong>
      </div>
      <img
        src={props.data.imgSrc}
        alt={props.data.title}
        className="process-image"
      />
      <h4 className="process-card-title" id="home-process-title">
        {props.data.title}
      </h4>
      <p className="process-description" id="home-process-description">
        {props.data.description}
      </p>
    </div>
  );
}

export default Process;
