import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import TopSection from "./topSection/topSection";
import RelativeImage from "./topSection/relativeImage";
import QueryFormSection from "./querySection/queryFormSection";
import QueryFormImage from "./querySection/queryFormImage";
import RequestDemo from "../../assets/images/rcw1.png";
import ContactImg from "../../assets/images/rcw11.png";
import WriteAQuery from "../../assets/images/rcw5.png";
import SocialSnackBar from "../../components/socialSnackBar/sociallinks";
import "./contactStyles.css";

function ContactUs() {
  const Contact = [
    {
      title: "Request a Demo",
      img: RequestDemo,
    },
    {
      title: "Contact Us",
      img: ContactImg,
    },
    {
      title: "Write a query",
      img: WriteAQuery,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Contact us | Rajala's Tech Academy</title>
        <meta
          name="description"
          content="Reach our support team to get more clarification about demo sessions and courses offered by our company."
        />
        <meta
          name="keywords"
          content="contact-us, assistant, help-us, phone number of Rajala's, find Rajala's academy, who is rajala, customer service, social media, rajala tech academy facebook , rajala tech academy instagram,rajala tech academy twitter, rajala tech academy whatsapp,  customer service assistance, contact us page text, care customer service,join as a teacher, join as a tutor, become a tutor, request a demo, how to register"
        />
      </Helmet>
      <section className="oep-contact-section">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-sm-12 main-theme-content"
              id="contactus-topsection"
            >
              <TopSection />
            </div>
            <div className="col-md-6 col-sm-12">
              <RelativeImage />
            </div>
          </div>
        </div>
      </section>
      <section className="reasons oep-contact-section">
        <SocialSnackBar />
        <div className="container">
          <div className="row">
            {Contact.map((contact, index) => {
              return (
                <Fragment key={index}>
                  <div className="col-md-4 col-lg-4 d-flex align-items-stretch  mb-lg-0 query-card-container">
                    <Card data={contact} step={index + 1} />
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </section>
      <section className="contact-form oep-contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 col-sm-12 px-5 pt-5">
              <QueryFormImage />
            </div>
            <div className="col-md-12 col-lg-6 col-sm-12 query-form-contact">
              <QueryFormSection />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function Card(props) {
  return (
    <div className="query-card">
      <div className="query-background">
        <img
          src={props.data.img}
          alt={props.data.title}
          className="query-card-image"
        />
      </div>
      <h4 className="query-card-title">{props.data.title}</h4>
    </div>
  );
}
export default ContactUs;
