import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonCourseHeader() {
  return (
    <SkeletonTheme color="#e6e6e6" highlightColor="rgb(255, 255, 255)">
      <div className="course-pills-skeleton">
        <Skeleton height={30} width={130} />
      </div>
    </SkeletonTheme>
  );
}

export default SkeletonCourseHeader;
