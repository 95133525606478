import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../../App";
import ServerErrImg from "../../assets/images/500-badgateway.png";

import "./errorModal.css";

function ErrorModal(props) {
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOpenModal(responseModal.errorModal);
    return () => {};
  }, [responseModal]);

  const closeModal = () => {
    setResponseModal({
      ...responseModal,
      successModal: false,
      errorModal: false,
    });
  };

  return (
    <>
      {openModal ? (
        <div className="bg-error-modal" onClick={closeModal}>
          <div className="error-modal-content">
            <div className="error-header">
              <button
                type="button"
                className="button-close"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true" className="close-btn">
                  &times;
                </span>
              </button>
            </div>
            <div>
              <img
                src={ServerErrImg}
                alt="server side error"
                className="error-img"
              />
            </div>
            <p className="err-msg-content">
              Something happened, we are working on it. Please try again later
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default ErrorModal;
