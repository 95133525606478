import React, { useState, Fragment, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import service from "../service";

function SearchBar(props) {
  const [messageprops] = useState("formSearch");

  let history = useHistory();
  let searchInput = useRef(null);
  const [userSearch, setUserSearch] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: "",
  });
  const [courseSuggestions, setCourseSuggestions] = useState([]);
  const [searchStatus, setSearchStatus] = useState("none");
  const [upcomingBatchStatus, setUpcomingBatchStatus] = useState(false);

  const [background, setBackgorund] = useState("");
  //searchbar block classnames according to different parents
  //to avoid in course page while searching
  useEffect(() => {
    if (
      props.msg === "formSearch" ||
      props.msg === "batchCourse" ||
      props.msg === "bookStatus"
    ) {
      //course page
      setBackgorund("none");
    } else {
      setBackgorund("#151414b3");
    }
  }, [props]);

  // const handleReSize = () => {
  //   if (window.outerWidth >= 1090.98) {
  //     closeSearch();
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", handleReSize);
  //   return () => {
  //     window.removeEventListener("resize", handleReSize);
  //   };
  // });
  useEffect(() => {
    const handleBatches = () => {
      setUserSearch({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: props.coursevalue,
      });
    };
    if (props.msg === "batchCourse") {
      if (
        props.coursevalue !== null &&
        props.coursevalue !== undefined &&
        upcomingBatchStatus === false
      ) {
        handleBatches();
      }
    } else if (props.msg === "bookStatus") {
      if (upcomingBatchStatus === false) {
        setUserSearch({
          activeSuggestion: 0,
          filteredSuggestions: [],
          showSuggestions: false,
          userInput: props.coursevalue,
        });
      }
    }
  }, [props, upcomingBatchStatus]);
  useEffect(() => {
    if (props.msg === "formSearch") {
      if (userSearch.userInput !== "") {
        props.handleModesOfTraining(false);
      } else {
        props.handleModesOfTraining(true);
      }
    } else if (props.msg === "batchCourse" || props.msg === "bookStatus") {
      if (upcomingBatchStatus === true) {
        if (userSearch.userInput !== "") {
          props.handleModesOfTraining(false);
        } else {
          props.handleModesOfTraining(true);
        }
      }
    }
  }, [props, userSearch.userInput, upcomingBatchStatus]);
  useEffect(() => {
    async function getData() {
      let data = await service.getAllCourses();
      if (data.length > 0) {
        let suggestions = data.map((course) => {
          return {
            courseID: course.course_id,
            courseName: course.course_name,
            course_id: course._id,
          };
        });
        setCourseSuggestions(suggestions);
      }
    }
    getData();
  }, []);
  useEffect(() => {
    if (searchStatus === "flex") {
      searchInput.current.focus();
    }
  }, [searchStatus]);
  const closeSearch = () => {
    setUserSearch({ ...userSearch, userInput: "" });
    setSearchStatus("none");
  };
  const OpenSearch = () => {
    setSearchStatus("flex");
    props.hide();
  };
  const closeDemoSearch = () => {
    if (props.msg === "batchCourse" || props.msg === "bookStatus") {
      setUpcomingBatchStatus(true);
    }
    setUserSearch({
      ...userSearch,
      showSuggestions: false,
      userInput: "",
    });
    props.handleUpcomingBatches([], "");
  };
  const closeSuggestions = () => {
    setUserSearch({
      ...userSearch,
      activeSuggestion: 0,
      showSuggestions: false,
    });
  };
  const onChange = (e) => {
    const userInput = e.target.value;
    if (userInput.trim() !== "") {
      // Filter our suggestions that don't contain the user's input
      const filteredSuggestions = courseSuggestions.filter(
        (suggestion) =>
          suggestion.courseName.toLowerCase().indexOf(userInput.toLowerCase()) >
          -1
      );
      setUserSearch({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.target.value,
      });
    } else {
      setUserSearch({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: true,
        userInput: "",
      });
    }
    if (props.msg === "batchCourse" || props.msg === "bookStatus") {
      setUpcomingBatchStatus(true);
    }
  };

  const showCourse = (cid, cname) => {
    setUserSearch({
      ...userSearch,
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
    });
    if (searchStatus === "flex") {
      setSearchStatus("none");
    }
    let url_coursename = cname.replace(/\s+/g, "-").toLowerCase();
    history.push(`/courses/view/?courseName=${url_coursename}&id=${cid}`);
  };
  const showUserInput = (courseID, courseName) => {
    setUserSearch({
      ...userSearch,
      userInput: courseName,
      activeSuggestion: 0,
      showSuggestions: false,
    });
    if (searchStatus === "flex") {
      setSearchStatus("none");
    }

    if (courseID !== "" && courseID !== undefined) {
      async function getCourseData() {
        let data = await service.getUpcomingEvents(courseID);
        if (
          props.msg === "formSearch" ||
          props.msg === "batchCourse" ||
          props.msg === "bookStatus"
        ) {
          props.handleModesOfTraining(false);
          props.handleUpcomingBatches(data, courseName);
        }
      }
      getCourseData();
    }
  };
  const onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = userSearch;

    // User pressed the enter key, update the input and close the suggestion
    if (e.keyCode === 13) {
      if (filteredSuggestions.length > 0) {
        let { courseName, courseID, course_id } = filteredSuggestions[
          activeSuggestion
        ];
        if (
          props.msg === "formSearch" ||
          props.msg === "batchCourse" ||
          props.msg === "bookStatus"
        ) {
          showUserInput(course_id, courseName);
        } else {
          showCourse(courseID, courseName);
        }
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setUserSearch({
        ...userSearch,
        userInput: filteredSuggestions[activeSuggestion - 1].courseName,
        activeSuggestion: activeSuggestion - 1,
      });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === filteredSuggestions.length) {
        return;
      }
      setUserSearch({
        ...userSearch,
        activeSuggestion: activeSuggestion + 1,
        userInput: filteredSuggestions[activeSuggestion + 1].courseName,
      });
    } else {
      setUserSearch({ ...userSearch, activeSuggestion: 0 });
    }
  };

  let suggestionsListComponent;
  if (userSearch.showSuggestions && userSearch.userInput) {
    if (userSearch.filteredSuggestions.length) {
      suggestionsListComponent = (
        <Fragment>
          <div
            className="bg-overlay"
            style={{ background: background }}
            onClick={closeSuggestions}
          ></div>
          <ul className="suggestions-list">
            {userSearch.filteredSuggestions.map((suggestion, index) => {
              let searchClass = "suggestion";
              let { filteredSuggestions, activeSuggestion } = userSearch;
              if (
                filteredSuggestions[activeSuggestion].courseID ===
                suggestion.courseID
              ) {
                searchClass = "suggestion active";
              }
              return (
                <li
                  className={searchClass}
                  key={suggestion.courseID}
                  tabIndex="0"
                  onClick={
                    props.msg === "formSearch" ||
                    props.msg === "batchCourse" ||
                    props.msg === "bookStatus"
                      ? () =>
                          showUserInput(
                            suggestion.course_id,
                            suggestion.courseName
                          )
                      : () =>
                          showCourse(suggestion.courseID, suggestion.courseName)
                  }
                >
                  {suggestion.courseName}
                </li>
              );
            })}
          </ul>
        </Fragment>
      );
    } else {
      suggestionsListComponent = (
        <Fragment>
          <div
            className="bg-overlay"
            onClick={closeSuggestions}
            style={{ background: background }}
          ></div>

          <ul className="suggestions-list">
            <li className="no-suggestions">
              <em>Sorry, did not match any course results</em>
            </li>
          </ul>
        </Fragment>
      );
    }
  }

  return (
    <React.Fragment>
      {props.msg === messageprops ||
      props.msg === "batchCourse" ||
      props.msg === "bookStatus" ? (
        <Fragment>
          <div className="select-course-search">
            <span className="search-form-icon">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              name="formSearch"
              className="select-course-input"
              type="text"
              placeholder="Select course"
              value={userSearch.userInput}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onFocus={onChange}
            />
            {userSearch.userInput.length > 1 ? (
              <span
                className="closedemobtn"
                onClick={closeDemoSearch}
                title="Close Overlay"
              >
                <FontAwesomeIcon icon={faTimes} color={"#989898"} />
              </span>
            ) : null}
            {suggestionsListComponent}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {/* <div className={_className}>
            <span
              className="SearchIcon"
              onClick={
                _className === "navSearchBar"
                  ? () => setClassName("navSearchBar focused")
                  : null
              }
              title={
                _className === "navSearchBar" ? "Click to search" : "Search"
              }
            >
              <FontAwesomeIcon
                icon={faSearch}
                size={"1x"}
                style={{ color: "grey" }}
              />
            </span>
            <div className="search-input-container">
              <input
                className="all-search-input"
                type="text"
                value={userSearch.userInput}
                onBlur={onOutOfFocus}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={onChange}
                placeholder="What is your interest"
              />
              {userSearch.userInput !== "" ? (
                <span
                  className="closebtn"
                  onClick={closeSearch}
                  title="Close Overlay"
                >
                  <FontAwesomeIcon icon={faTimes} color={"#989898"} />
                </span>
              ) : null}
            </div>

            {suggestionsListComponent}
          </div> */}
          {props.msg === "navbar-search" ? (
            <div className="search-responsive navbar ml-auto">
              <span
                className="search-responsive-button"
                onClick={OpenSearch}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ color: "rgb(157, 165, 174)", fontSize: "1.5em" }}
                />
              </span>
              <div
                id="myOverlay"
                className="overlay"
                style={{ display: searchStatus }}
              >
                <input
                  type="text"
                  className="search-courses-menu"
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  onFocus={onChange}
                  ref={searchInput}
                  value={userSearch.userInput}
                  placeholder="What is your interest"
                />
                <span
                  className="closebtn"
                  onClick={closeSearch}
                  title="Close Overlay"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                {suggestionsListComponent}
              </div>
            </div>
          ) : null}
        </Fragment>
      )}
    </React.Fragment>
  );
}

export default SearchBar;
