import "./notFoundStyles.css";
import React from "react";
import { Helmet } from "react-helmet";
import NotFoundImage from "../../assets/images/404NotFound.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

function NotFound() {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>404 Page Not Found | Rajala's Tech Academy</title>
        <meta
          name="description"
          content="The Page you requested could not be found in this website. Please check our website https://rajalas-academy.com"
        />
      </Helmet>
      <div className="container-not-found ">
        <div className="row">
          <div className="message-404 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <h1>Ooops!!</h1>
            <h1 className="sr-only">404 Error!</h1>
            <strong>The Page you requested could not be found</strong>
            <a className="go-home-btn" href="/home">
              <FontAwesomeIcon icon={faHome} style={{ marginRight: 8 }} />
              go to home
            </a>
          </div>
          <div className="message-image col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <img src={NotFoundImage} alt="Page is not found" />
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
