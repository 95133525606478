import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./socialSnackStyles.css";
import useReactEventTracker from "../../hooks/react-ga";

export default function SocialSnackBar() {
  const gaEventTracker = useReactEventTracker("User-Interaction-Contact-Us-Page");
  function gaContact(socialPlatform){
    gaEventTracker(`${socialPlatform} link Button is Clicked by the User`);
  }

  return (
    <div className="social-snack-bar">
      <ul className="social-links-container">
        <li
          className="social-link-nav whatsapp-link"
          id="contactus-social-snackbar-whatsapp"
        >
          <a href="tel:‎9092773127"  
          onClick={()=>{
            gaContact("WhatsApp");
          }}
          >  
            <FontAwesomeIcon icon={faWhatsapp} size="1x" />
          </a>
        </li>
        <li
          className="social-link-nav facebook-link"
          id="contactus-social-snackbar-facebook"
        >
          <a
            href="https://www.facebook.com/Rajalas-Tech-Academy-101822064992885/"
            rel="noopener noreferrer"
            target="_blank"
            onClick={()=>{
              gaContact("Facebook");
            }}
          >
            <FontAwesomeIcon icon={faFacebookF} size="1x" />
          </a>
        </li>
        <li
          className="social-link-nav youtube-link"
          id="contactus-social-snackbar-youtube"
        >
          <a
            href="https://www.youtube.com/channel/UCmJhfku5WFCD4oMB5FZFAqg?view_as=subscriber"
            rel="noopener noreferrer"
            target="_blank"
            onClick={()=>{
              gaContact("YouTube");
            }}
          >
            <FontAwesomeIcon icon={faYoutube} size="1x" />
          </a>
        </li>
        <li
          className="social-link-nav insta-link"
          id="contactus-social-snackbar-instagram"
        >
          <a
            href="https://www.instagram.com/rajalas_tech_academy/"
            rel="noopener noreferrer"
            target="_blank"
            onClick={()=>{
              gaContact("Instagram");
            }}
          >
            <FontAwesomeIcon icon={faInstagram} size="1x" />
          </a>
        </li>
        <li
          className="social-link-nav twitter-link"
          id="contactus-social-snackbar-twitter"
        >
          <a
            href="https://twitter.com/RajalaTech"
            rel="noopener noreferrer"
            target="_blank"
            onClick={()=>{
              gaContact("Twitter");
            }}
          >
            <FontAwesomeIcon icon={faTwitter} size="1x" />
          </a>
        </li>
        <li
          className="social-link-nav LinkedIn-link"
          id="contactus-social-snackbar-linkedIn"
        >
          <a
            href="https://www.linkedin.com/in/rajala-s-tech-academy-1621091b6/"
            rel="noopener noreferrer"
            target="_blank"
            onClick={()=>{
              gaContact("LinkedIn");
            }}
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
          </a>
        </li>
      </ul>
    </div>
  );
}
