import LogoImg from "../../assets/images/Rajalas_Academy_Logo.svg";
import React, { Fragment, useState, useContext, useEffect } from "react";
import Media from "react-media";
import { NavLink, Link } from "react-router-dom";
import SearchBar from "../searchBar";
import ModalView from "../modalView";
import { ModalContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faBars,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import "./navBarStyles.css";
import useReactEventTracker from "../../hooks/react-ga";

function Navbar() {
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [showNavBarsm, setShowNavBarsm] = useState(false);
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const gaEventTracker = useReactEventTracker("User-Interaction-Home-Page");
  const openModal = (key) => {
    setModalShow(true);
    setModalType(key === "reqDemoModal" ? "Request-demo" : "Become-tutor");
    if(key === "reqDemoModal" || key === "Request-demo"){
      gaEventTracker("Home Request-a-Demo Button Clicked by the User");
    }
    else if(key === "Become-tutor"){
      gaEventTracker("Home Become-tutor Button Clicked by the User");
    }
    setResponseModal({ ...responseModal, [key]: true });
  };
  const closeModal = () => {
    setModalShow(false);
    setModalType("");
    setResponseModal({
      ...responseModal,
      reqDemoModal: false,
      beTutorModal: false,
    });
  };

  useEffect(() => {
    if (
      responseModal.reqDemoModal === false &&
      responseModal.beTutorModal === false &&
      modalShow
    ) {
      setModalShow(false);
    }
  }, [responseModal, modalShow]);

  useEffect(() => {
    function handleReSize() {
      // if (window.innerWidth >= 991.98 && showNavBarsm) {
      if (window.innerWidth >= 1050.98 && showNavBarsm) {
        setShowNavBarsm(false);
      }
    }
    window.addEventListener("resize", handleReSize);
    return () => {
      window.removeEventListener("resize", handleReSize);
    };
  });

  return (
    <div className="main-navigation">
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm bg-white"
        style={{ zIndex: "999" }}
      >
        <Link className="navbar-brand mb-0 h1 d-flex " to="/home">
          <img
            src={LogoImg}
            alt="Rajala's Tech Academy"
            className="companyLogo"
          />
        </Link>
        <div className="nav-sub-wrap d-flex align-items-center">
          {/* <Media queries={{ large: { minWidth: 991.98 } }}> */}
          <Media queries={{ large: { minWidth: 1050.98 } }}>
            {(matches) =>
              matches.large ? (
                <Fragment>
                  <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav ml-auto align-items-center">
                      <SearchBar
                        msg="navbar-search"
                        status={false}
                        hide={() => setShowNavBarsm(false)}
                      />
                      <li className="navigation-list" id="navbar-navlink-home">
                        <NavLink
                          className="nav-link-item"
                          activeClassName="active"
                          to="/home"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li
                        className="navigation-list"
                        id="navbar-navlink-courses"
                      >
                        <NavLink
                          className="nav-link-item"
                          activeClassName="active"
                          to="/courses"
                        >
                          Courses
                        </NavLink>
                      </li>
                      <li
                        className="navigation-list"
                        id="navbar-navlink-contact-us"
                      >
                        <NavLink
                          className="nav-link-item"
                          activeClassName="active"
                          to="/contact-us"
                        >
                          Contact us
                        </NavLink>
                      </li>
                      <li
                        className="navigation-list"
                        id="navbar-navlink-be-tutor"
                      >
                        <button
                          className="become-tutor-btn"
                          onClick={() => openModal("beTutorModal")}
                          id="navbar-navlink-become-tutor-btn"
                        >
                          Become a tutor
                          <span className="DemoIcon">
                            <FontAwesomeIcon icon={faChalkboardTeacher} />
                          </span>
                        </button>
                      </li>
                      <li
                        className="navigation-list"
                        id="navbar-navlink-live-demo"
                      >
                        <button
                          className="live-demo-btn"
                          onClick={() => openModal("reqDemoModal")}
                          id="navbar-navlink-live-demo-btn"
                        >
                          Request a Demo
                          <span className="DemoIcon">
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <ModalView
                    type={modalType}
                    show={modalShow}
                    onHide={closeModal}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <button
                    className="live-demo-btn"
                    onClick={() => openModal("reqDemoModal")}
                    id="navbar-navlink-live-demo-btn"
                  >
                    Demo
                    <span className="DemoIcon">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                  </button>
                  <SearchBar
                    msg="navbar-search"
                    status={showNavBarsm}
                    hide={() => setShowNavBarsm(false)}
                  />
                  <button
                    className="navbar-toggler navigation-toggle-button"
                    type="button"
                    onClick={() => setShowNavBarsm(!showNavBarsm)}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faBars}
                        style={{ color: "#9da5ae", fontSize: "1.5em" }}
                      />
                    </span>
                  </button>
                  <div
                    className={
                      showNavBarsm
                        ? "navbar-collapse-sm show"
                        : "navbar-collapse-sm"
                    }
                    id="collapsibleNavbar"
                  >
                    <ul className="nav navbar-nav ml-auto align-items-center">
                      <li
                        className="navigation-list"
                        onClick={() => setShowNavBarsm(false)}
                        id="navbar-navlink-home"
                      >
                        <NavLink
                          className="nav-link-item"
                          activeClassName="active"
                          to="/home"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li
                        className="navigation-list"
                        onClick={() => setShowNavBarsm(false)}
                        id="navbar-navlink-courses"
                      >
                        <NavLink
                          className="nav-link-item"
                          activeClassName="active"
                          to="/courses"
                        >
                          Courses
                        </NavLink>
                      </li>
                      <li
                        className="navigation-list"
                        onClick={() => setShowNavBarsm(false)}
                        id="navbar-navlink-contact-us"
                      >
                        <NavLink
                          className="nav-link-item"
                          activeClassName="active"
                          to="/contact-us"
                        >
                          Contact us
                        </NavLink>
                      </li>
                      {/* <li
                        className="navigation-list"
                        onClick={() => setShowNavBarsm(false)}
                        id="navbar-navlink-be-tutor"
                      >
                        <button
                          className="become-tutor-btn"
                          onClick={() => openModal("beTutorModal")}
                          id="navbar-navlink-become-tutor-btn"
                        >
                          Become a tutor
                          <span className="DemoIcon">
                            <FontAwesomeIcon icon={faChalkboardTeacher} />
                          </span>
                        </button>
                      </li>
                      <li
                        className="navigation-list"
                        onClick={() => setShowNavBarsm(false)}
                        id="navbar-navlink-live-demo"
                      >
                        <button
                          className="live-demo-btn"
                          onClick={openModal}
                          id="navbar-navlink-live-demo-button"
                        >
                          Request a Demo
                          <span className="DemoIcon">
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                        </button>
                      </li> */}
                    </ul>
                  </div>
                  <ModalView
                    type="Request-demo"
                    show={modalShow}
                    onHide={closeModal}
                  />
                </Fragment>
              )
            }
          </Media>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
