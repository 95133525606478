import React from "react";
import { Modal } from "react-bootstrap";
import QueryForm from "./queryForm/queryForm";

function ModalView(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="query-form-modal"
      className="query-modal"
    >
      <Modal.Header
        closeButton
        className="custom-modal-header px-3 d-flex align-items-center"
      >
        <h5 id="query-form-modal-header" className="modal-popup-title">
          {props.type === "Request-demo" ||
          props.type === "batches-modal" ||
          props.type === "book"
            ? "Request a Demo"
            : props.type === "modal-to-popup"
            ? "Let's talk"
            : props.type === "Become-tutor"
            ? "Join us"
            : "Query"}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-form">
          {props.type === "batches-modal" ? (
            <QueryForm
              type={props.type}
              eventdata={props.eventdata}
              events={props.events}
              coursename={props.coursename}
            />
          ) : props.type === "book" ? (
            <QueryForm
              type={props.type}
              coursename={props.coursename}
              events={props.events}
            />
          ) : (
            <QueryForm type={props.type} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ModalView;
