import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { CourseContext } from "../courseIndex";
import GridCard from "../../../components/gridCard/gridCard";
import SkeletonGridCard from "../../../components/gridCard/loadingGridCard/skeleton";
import ListCard from "../../../components/listCard/listCard";
import SkeletonListCard from "../../../components/listCard/loadingListCard/skeleton";
// import SearchBar from "../../../components/searchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTh,
  faBars,
  faSortAmountDown,
  faSpinner,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import NoCourseImg from "../../../assets/images/noCourses.png";
import { sortArray } from "./allCourse";
import service from "../../../service";
import "./allCourses.css";

export default function AllCourses() {
  const [allcourseData, setAllCourseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentView, setCurrentView] = useState("grid");
  const [sortType, setSortType] = useState("");
  const [isTriggeredSort, setIsTriggeredSort] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [isAscending, setIsAscending] = useState(false);
  const courseContext = useContext(CourseContext);
  const [searchInput, setSearchInput] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  let localCourses = useRef([]);

  useEffect(() => {
    //   Here we will make a api call to get data from database.
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function getData() {
      let data = await service.getAllCourses({ signal: signal });
      if (data !== undefined && data !== null) {
        // localStorage.setItem("coursesList", JSON.stringify(data));
        localCourses.current = data;
        if (data.length > 5) {
          //need to changed to 15
          data = data.slice(0, 4); //data = data.slice(0, 14);
        }
        setAllCourseData(data);
      }
      setIsLoading(false);
      setSortType("rating");
    }
    getData();
    return function cleanup() {
      abortController.abort();
    };
  }, []);
  //this useEffect is to hanlde the search course changes
  useEffect(() => {
    if (isSearching) {
      if (searchInput.trim() !== "") {
        const filteredSuggestions = localCourses.current.filter((course) => {
          if (
            course.course_category.value &&
            course.course_category.value
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1
          ) {
            return (
              course.course_category.value
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) > -1
            );
          } else {
            return (
              course.course_name
                .toLowerCase()
                .indexOf(searchInput.toLowerCase()) > -1
            );
          }
        });
        setAllCourseData(filteredSuggestions);
        setIsSearching(false);
      } else {
        setAllCourseData(localCourses.current);
        setIsSearching(false);
      }
    }
    return () => {};
  }, [searchInput, isSearching, localCourses]);
  // This effect is to handle the course category selection from the top.
  useEffect(() => {
    let selectedCategory = courseContext[0];
    if (selectedCategory !== "") {
      setSearchInput(selectedCategory);
      setIsSearching(true);
      setShowSearchResult(true);
    }
    return () => {};
  }, [courseContext]);
  useEffect(() => {
    if (allcourseData.length > 0 && isTriggeredSort) {
      var sorted = sortArray(allcourseData, sortType, isAscending);
      setAllCourseData(sorted);
      setIsTriggeredSort(false);
    }
    return () => {};
  }, [sortType, isAscending, allcourseData, isTriggeredSort]);

  const sortData = (value, sortAscending) => {
    setIsAscending(sortAscending);
    setSortType(value);
    setIsTriggeredSort(true);
    setShowSort(false);
  };

  const toggleView = (view) => {
    setCurrentView(view);
  };
  const handleSearchChange = (val) => {
    setSearchInput(val);
    setIsSearching(true);
    setShowSearchResult(true);
  };

  const clearSearchInput = () => {
    setSearchInput("");
    setIsSearching(true);
    setShowSearchResult(false);
  };

  const loadMoreCourses = () => {
    setLoadingMore(true);
    setTimeout(() => {
      if (localCourses.current.length > allcourseData.length) {
        setAllCourseData(localCourses.current);
      }
      setLoadingMore(false);
    }, 3000);
  };
  return (
    <div className="all-courses-container">
      <div className="courses-header mb-5">
        <div className="search-courses" id="courses-searchbar">
          <div className="search-course-container">
            <input
              type="text"
              name="search-course"
              value={searchInput}
              onChange={(e) => handleSearchChange(e.target.value)}
              onBlur={() => setShowSearchResult(false)}
              className="all-course-search"
              id="all-course-search"
              placeholder="Search for courses"
            />
            {searchInput.trim() !== "" ? (
              <button className="clear-search-input" onClick={clearSearchInput}>
                clear
              </button>
            ) : null}
          </div>
          <p
            className={
              showSearchResult
                ? "search-results-info show"
                : "search-results-info"
            }
          >
            Search results :{" "}
            <strong>
              {searchInput.trim() !== "" ? allcourseData.length : 0}
              <small>results</small>
            </strong>
          </p>
        </div>
        <div className="course-action">
          <div className="filter-option">
            <div className="dropdown">
              <div
                className="filter-icon"
                id="courses-filter"
                tabIndex="0"
                title="sort by"
              >
                <FontAwesomeIcon
                  icon={faSortAmountDown}
                  aria-labelledby="courses-filter"
                  onClick={() => setShowSort(!showSort)}
                />

                <ul
                  className={
                    showSort ? "dropdown-content show" : "dropdown-content"
                  }
                >
                  <li
                    onClick={() => sortData("rating")}
                    id="courses-filter-rating"
                    title="sort by rating"
                  >
                    Rating
                  </li>
                  <li
                    onClick={() => sortData("created_on")}
                    id="courses-filter-latest"
                    title="sort by recent"
                  >
                    Latest
                  </li>
                  <li
                    onClick={() => sortData("course_name", true)}
                    id="courses-filter-ascending"
                    title="ascending order"
                  >
                    Ascending
                  </li>
                  <li
                    onClick={() => sortData("course_name", false)}
                    id="courses-filter-descending"
                    title="descending order"
                  >
                    Descending
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="course-view-option">
            <button
              tabIndex="0"
              id="courses-grid-view"
              className={
                currentView === "grid" ? "grid-view active" : "grid-view"
              }
              title="Grid-view"
              onClick={() => toggleView("grid")}
            >
              <FontAwesomeIcon icon={faTh} />
            </button>
            <button
              tabIndex="0"
              id="courses-list-view"
              className={
                currentView === "list" ? "list-view active" : "list-view"
              }
              title="List-view"
              onClick={() => toggleView("list")}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          allcourseData.length === 0
            ? "courses-list empty"
            : currentView === "grid"
            ? "courses-list grid"
            : "courses-list list"
        }
      >
        {!isLoading && allcourseData.length > 0 ? (
          allcourseData.map((course, index) => (
            <Fragment key={index}>
              {currentView === "grid" ? (
                <GridCard data={course} />
              ) : (
                <ListCard data={course} />
              )}
            </Fragment>
          ))
        ) : !isLoading && allcourseData.length === 0 ? (
          <NoCourses />
        ) : (
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((el) => (
            <Fragment key={el}>
              {currentView === "grid" ? (
                <SkeletonGridCard />
              ) : (
                <SkeletonListCard />
              )}
            </Fragment>
          ))
        )}
      </div>
      {searchInput.trim() === "" &&
      allcourseData.length !== localCourses.current.length ? (
        <div className="d-flex justify-content-center py-3">
          <button
            className="btn btn-load-more"
            aria-label="load-more"
            onClick={loadMoreCourses}
          >
            Load more
            {loadingMore ? (
              <FontAwesomeIcon
                rotation={90}
                icon={faSpinner}
                className="ml-2"
              />
            ) : (
              <FontAwesomeIcon icon={faAngleDoubleDown} className="ml-2" />
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
}

function NoCourses() {
  return (
    <div className="no-course-container container">
      <h2 className="title">No Course found</h2>
      <img src={NoCourseImg} alt="No Course Found" className="no-course-img" />
    </div>
  );
}
