import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./scrollTopButton.css"
function ScrollTopButton(){
    const [scrollMenu, setScrollMenu] = useState("scroll-fade-out");
  useEffect(() => {
    const handleScroll = () => { 
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos < 200) {
        setScrollMenu("scroll-fade-out")
      }
      else {
        setScrollMenu("scroll-fade-in")
      }
    }
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollMenu]);
  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  return(
    <button
    className={scrollMenu}
    onClick={handleClick}
  >
    <FontAwesomeIcon icon={faAngleUp} />
  </button>
  )

}
export default ScrollTopButton;