import "./setupTests.js";
import React from "react";
import { render } from "react-snapshot";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import App from "./App";
const rootElement = document.getElementById("root");

render(<App />, rootElement);
