import React from "react";
import ContactUsImage from "../../../assets/images/contactus_query.png";
function QueryFormImage() {
  return (
    <img
      src={ContactUsImage}
      className="query-section-image"
      alt="contact our frontdesk"
      id="contact-us-queryform-image"
    />
  );
}
export default QueryFormImage;
