import React, { useState, useContext, Fragment, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import SearchBar from "../searchBar";
import { ModalContext } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";
import service from "../../service";
import "react-phone-input-2/lib/style.css";
import "./queryForm.css";
// import useReactEventTracker from "../../hooks/react-ga";

function QueryForm(props) {
  const [modalmessage] = useState("Request-demo");
  const [modeVisible, setModeVisible] = useState(true);
  const [batchesVisible, setBatchesVisible] = useState(false);
  const [upcomingBatches, setUpcomingBatches] = useState([]);
  const [batchStatus, setBatchStatus] = useState(false);
  const [checking, setChecking] = useState(false);
  const [bookStatus, setBookStatus] = useState(false);
  const [sendingReq, setSendingReq] = useState(false);
  // eslint-disable-next-line
  const [responseModal, setResponseModal] = useContext(ModalContext);
  //all error messages
  const [errormessages, setErrorMessages] = useState({
    nameErrorMessage: "",
    emailErrorMessage: "",
    mobileErrorMessage: "",
    messageErrorMessage: "",
    courseErrorMessage: "",
    ModeOfTrainingErrorMessage: "",
    UpcomingBatchesErrorMessage: "",
    typeOfRequest: "",
  });
  const [queryform, setQueryform] = useState({
    formName: "",
    formEmail: "",
    formMobile: "",
    formMessage: "",
    typeOfRequest: "",
  });

  const [modalform, setModalform] = useState({
    formName: "",
    formEmail: "",
    formMobile: "",
    formSearch: "",
    formModeOfTraining: "",
    formUpcomingBatches: "",
    formMessage: "",
    typeOfRequest: "Request-demo",
  });

  const [phoneNo] = useState("");

  // const gaEventTracker = useReactEventTracker("User-Interaction-form");

  useEffect(() => {
    const handleBatches = (selectedevent, coursename) => {
      setModalform({
        formName: "",
        formEmail: "",
        formMobile: "",
        formMessage: "",
        formModeOfTraining: "batch",
        formUpcomingBatches: selectedevent,
        formSearch: coursename,
        typeOfRequest: "Request-demo",
      });
      setModeVisible(false);
      setBatchStatus(true);
      setChecking(true);
      setBatchesVisible(true);
    };
    if (props.type === "batches-modal") {
      if (props.coursename !== "" && props.events.length !== 0) {
        let data = props.eventdata;
        let selectedEvent = "";
        let eventsString = "";
        let batchesarray = [];
        let discount = "Discount";
        if (!data.eventDiscount.enabled) {
          eventsString =
            data.eventDate + " " + data.eventMonth + ", " + data.eventTime;
        } else {
          eventsString = `${data.eventDate} ${data.eventMonth} ${data.eventTime}`;
          if (parseInt(data.eventDiscount.rate) > 0) {
            eventsString += ` ${data.eventDiscount.rate}% ${discount}`;
          }
        }
        //to display the selected event first
        props.events.forEach((data) => {
          if (
            props.eventdata.eventDate === data.eventDate &&
            props.eventdata.eventMonth === data.eventMonth &&
            props.eventdata.eventTime === data.eventTime
          ) {
            if (!data.eventDiscount.enabled) {
              eventsString =
                data.eventDate + " " + data.eventMonth + ", " + data.eventTime;
            } else {
              eventsString = `${data.eventDate} ${data.eventMonth} ${data.eventTime}`;
              if (parseInt(data.eventDiscount.rate) > 0) {
                eventsString += ` ${data.eventDiscount.rate}% ${discount}`;
              }
            }

            batchesarray.push(eventsString);
          }
        });
        //to add other events in the upcoming array
        props.events.forEach((data) => {
          if (
            !(
              props.eventdata.eventDate === data.eventDate &&
              props.eventdata.eventMonth === data.eventMonth &&
              props.eventdata.eventTime === data.eventTime
            )
          ) {
            if (!data.eventDiscount.enabled) {
              eventsString =
                data.eventDate + " " + data.eventMonth + ", " + data.eventTime;
            } else {
              eventsString = `${data.eventDate} ${data.eventMonth} ${data.eventTime}`;
              if (parseInt(data.eventDiscount.rate) > 0) {
                eventsString += ` ${data.eventDiscount.rate}% ${discount}`;
              }
            }

            batchesarray.push(eventsString);
          }
        });
        setUpcomingBatches(batchesarray);
        handleBatches(selectedEvent, props.coursename);
      }
    } else if (props.type === "book") {
      let eventsString = "";
      let batchesarray = [];
      let discount = "Discount";
      props.events.forEach((data) => {
        if (!data.eventDiscount.enabled) {
          eventsString =
            data.eventDate + " " + data.eventMonth + ", " + data.eventTime;
        } else {
          eventsString = `${data.eventDate} ${data.eventMonth} ${data.eventTime}`;
          if (parseInt(data.eventDiscount.rate) > 0) {
            eventsString += ` ${data.eventDiscount.rate}% ${discount}`;
          }
        }
        batchesarray.push(eventsString);
      });
      setUpcomingBatches(batchesarray);
      setModalform({
        formName: "",
        formEmail: "",
        formMobile: "",
        formMessage: "",
        formModeOfTraining: "",
        formUpcomingBatches: "",
        formSearch: props.coursename,
        typeOfRequest: "Request-demo",
      });
      setBookStatus(true);
      setModeVisible(false);
    }
  }, [props]);

  const handleUpcomingBatches = (events, courseName) => {
    let batchesarray = [];
    let eventsString = "";
    events.forEach((data) => {
      if (data.eventDiscount.rate === "0%") {
        eventsString =
          data.eventDate + " " + data.eventMonth + ", " + data.eventTime;
      } else {
        let discount = "Discount";
        eventsString =
          data.eventDate +
          " " +
          data.eventMonth +
          ", " +
          data.eventTime +
          " " +
          data.eventDiscount.rate +
          " " +
          discount;
      }
      batchesarray.push(eventsString);
    });
    setUpcomingBatches(batchesarray);
    setModalform({ ...modalform, formSearch: courseName });
  };

  const handleModesOfTraining = (status) => {
    if (status === true) {
      setBatchesVisible(false);
      setChecking(false);
      setBatchStatus(false);
      setBookStatus(false);
    }
    setModeVisible(status);
  };

  const handleChangePhone = (phone) => {
    if (
      props.type === modalmessage ||
      props.type === "batches-modal" ||
      props.type === "book"
    ) {
      setModalform({
        ...modalform,
        formMobile: phone,
      });
    } else {
      setQueryform({ ...queryform, formMobile: phone });
    }
  };

  const handleClick = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data;
    if (
      props.type === modalmessage ||
      props.type === "batches-modal" ||
      props.type === "book"
    ) {
      if (name === "formModeOfTraining") {
        if (value === "batch") {
          data = false;
          setBatchesVisible(true);
          setChecking(true);
        } else {
          data = true;
          setBatchStatus(false);
          setBatchesVisible(false);
          setChecking(false);
        }
      }
      if (name === "formUpcomingEvents") {
        data = false;
        setBatchStatus(false);
      }
      if (data) {
        setModalform({
          ...modalform,
          [name]: value,
          formUpcomingBatches: "",
        });
      } else {
        setModalform({
          ...modalform,
          [name]: value,
        });
      }
    } else {
      if (props.type === "query" || props.type === "modal-to-popup") {
        setQueryform({
          ...queryform,
          [name]: value,
          typeOfRequest: "query",
        });
      } else {
        setQueryform({
          ...queryform,
          [name]: value,
          typeOfRequest: props.type,
        });
      }
    }
  };

  async function queryDataSubmit(queryFormInput) {
    setSendingReq(true);
    let queryResponse = await service.queryFormSubmit(queryFormInput);
    let tempResponseContext = { ...responseModal };
    tempResponseContext.queryModal = false;
    tempResponseContext.beTutorModal = false;
    tempResponseContext.reqDemoModal = false;
    if (queryResponse.status === 500) {
      tempResponseContext.errorModal = true;
    } else {
      tempResponseContext.successModal = true;
    }
    setResponseModal(tempResponseContext);
    setSendingReq(false);
  }

  const formValidation = () => {
    let name = "";
    let email = "";
    let mobile = "";
    let search = "";
    let emailregex = /^\w+([.-]?\w+)*@[a-z0-9_]+([.-]?[a-z0-9_]+)*(\.[a-z0-9_]{2,3})+$/;
    let nameregex = /^[A-Za-z ]{1,}$/;
    if (
      props.type === modalmessage ||
      props.type === "batches-modal" ||
      props.type === "book"
    ) {
      let { formName, formEmail, formMobile, formSearch } = modalform;
      if (formName === "") {
        name = "Name is required";
      } else if (formName.length <= 2) {
        name = "Name should not be less than 3 characters";
      } else if (formName.match(nameregex) === null) {
        name = "Name should contain alphabets only";
      } else {
        name = "";
      }
      if (formEmail === "") {
        email = "Email ID is required";
      } else if (formEmail.match(emailregex) === null) {
        email = "Please enter a valid Email ID";
      } else {
        email = "";
      }
      if (formMobile === "") {
        mobile = "Mobile Number is required";
      } else if (formMobile.length < 7) {
        mobile = "Please enter a valid number";
      } else {
        mobile = "";
      }
      if (formSearch === "") {
        search = "Please select a course";
      } else {
        search = "";
      }

      setErrorMessages({
        ...errormessages,
        courseErrorMessage: search,
        emailErrorMessage: email,
        nameErrorMessage: name,
        mobileErrorMessage: mobile,
      });
      if (name === "" && email === "" && mobile === "" && search === "") {
        //backend call with modalform state object
        queryDataSubmit(modalform);
      } else {
        return;
      }
    } else {
      let { formName, formEmail, formMobile } = queryform;
      if (formName === "") {
        name = "Name is required";
      } else if (formName.length <= 2) {
        name = "Name should not be less than 3 characters";
      } else if (formName.match(nameregex) === null) {
        name = "Name should contain alphabets only";
      } else {
        name = "";
      }
      if (formEmail === "") {
        email = "Email ID is required";
      } else if (formEmail.match(emailregex) === null) {
        email = "Please enter a valid Email ID";
      } else {
        email = "";
      }
      if (formMobile === "") {
        mobile = "Mobile Number is required";
      } else if (formMobile.length < 7) {
        mobile = "Please enter a valid number";
      } else {
        mobile = "";
      }
      setErrorMessages({
        ...errormessages,
        emailErrorMessage: email,
        nameErrorMessage: name,
        mobileErrorMessage: mobile,
      });
      if (name === "" && email === "" && mobile === "") {
        //backend call with "queryform state object"//
        queryDataSubmit(queryform);
      } else {
        return;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="form-fields">
        <label htmlFor="queryname">Name </label>
        <sup className="mandatory-fields" style={{ size: "40px" }}>
          *
        </sup>
        <input
          placeholder="Your Name"
          className="form-area"
          type="text"
          name="formName"
          onChange={handleClick}
          autoComplete="off"
          list="autocompleteOff"
        />
        <span className="error-message">{errormessages.nameErrorMessage}</span>
      </div>
      <div className="form-fields">
        <label htmlFor="queryemail">E-mail</label>
        <sup className="mandatory-fields">*</sup>
        <input
          placeholder="Your E-mail"
          className="form-area"
          type="text"
          name="formEmail"
          onChange={handleClick}
          autoComplete="off"
          list="autocompleteOff"
        />
        <span className="error-message">{errormessages.emailErrorMessage}</span>
      </div>
      <div className="form-fields mb-2">
        <label htmlFor="querymobile">Mobile</label>
        <sup className="mandatory-fields">*</sup>
        <PhoneInput
          country={"in"}
          value={phoneNo}
          name="formMobile"
          onChange={(phoneNo) => handleChangePhone(phoneNo)}
          inputStyle={{ width: "100%" }}
        />
        <span className="error-message">
          {errormessages.mobileErrorMessage}
        </span>
      </div>
      {props.type === modalmessage ||
      props.type === "batches-modal" ||
      props.type === "book" ? (
        <Fragment>
          <div className="form-fields">
            <label htmlFor="modalcourse">Search course to select</label>
            <sup className="mandatory-fields">*</sup>
            <SearchBar
              msg={
                batchStatus
                  ? "batchCourse"
                  : bookStatus
                  ? "bookStatus"
                  : "formSearch"
              }
              coursevalue={
                batchStatus || bookStatus ? modalform.formSearch : null
              }
              handleModesOfTraining={handleModesOfTraining}
              handleUpcomingBatches={handleUpcomingBatches}
            />
            <span className="error-message">
              {errormessages.courseErrorMessage}
            </span>
          </div>
          {modeVisible === false || batchStatus ? (
            <Fragment>
              <div className="form-fields">
                <label>Modes Of Training</label>
                <div className="check-box-upcoming">
                  <div className="form-check-inline">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="selfpaced"
                        value="self-paced"
                        name="formModeOfTraining"
                        onChange={handleClick}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selfpaced"
                      >
                        Self-paced
                      </label>
                    </div>
                  </div>
                  <div className="form-check-inline pb-2">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="batch"
                        value="batch"
                        checked={checking}
                        name="formModeOfTraining"
                        onChange={handleClick}
                      />
                      <label className="custom-control-label" htmlFor="batch">
                        Batch
                      </label>
                    </div>
                  </div>
                  {batchesVisible || batchStatus ? (
                    <div className="form-check-inline">
                      <select
                        className="form-area upcomingBatches"
                        name="formUpcomingBatches"
                        defaultValue="novalue"
                        onChange={handleClick}
                        style={{ fontStyle: "italic" }}
                      >
                        {batchStatus ? null : (
                          <option disabled value="novalue">
                            Upcoming Batches
                          </option>
                        )}
                        {upcomingBatches.length > 0 ? (
                          upcomingBatches.map((events, index) => (
                            <option key={index} step={index + 1} value={events}>
                              {events}
                            </option>
                          ))
                        ) : (
                          <option value="None">
                            Suggest a date and time through message
                          </option>
                        )}
                      </select>
                    </div>
                  ) : null}
                </div>
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      ) : null}
      <div className="form-fields">
        <label htmlFor="querymsg">Message</label>
        <textarea
          className="form-area"
          placeholder="Your Message"
          name="formMessage"
          onChange={handleClick}
        />
      </div>

      <div className="form-fields justify-content-end">
        <button
          className="modal-button"
          type="button"
          onClick={formValidation}
          disabled={sendingReq}
        >
          {sendingReq ? (
            <>
              SENDING...
              <span className="modalIcon">
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="pending-req-icon"
                />
              </span>
            </>
          ) : (
            <>
              SUBMIT
              <span className="modalIcon">
                <FontAwesomeIcon icon={faPaperPlane} />
              </span>
            </>
          )}
        </button>
      </div>
    </form>
  );
}
export default QueryForm;
