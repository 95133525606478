import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonVideoPlayer() {
  return (
    <SkeletonTheme color="#dcdcdc" highlightColor="rgb(255, 255, 255)">
      <div className="video-container">
        <div className="responsive-skeleton-frame">
          <Skeleton height={300} width={`100%`} count={1} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default SkeletonVideoPlayer;
