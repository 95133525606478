import "./coursesHeader.css";
import React, { useContext, Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { CourseContext } from "../courseIndex";
import SkeletonHeader from "./skeletonCoursesHeader";
import { forLoadMore } from "./coursesHeaderFile";
import service from "../../../service";
import useReactEventTracker from "../../../hooks/react-ga";


function CourseHeader() {
  const [courseCategory, setCourseCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [currentCategory, setCurrentCategory] = useContext(CourseContext);
  const [minPillsCount, setMinPillsCount] = useState(5);
  const gaEventTracker = useReactEventTracker("User-Interaction-Course-Page");
  const [keyWords, setKeyWords] = useState(
    "Best Online Courses offered, IT & Software Courses, Development, Networking, Programming, Handson training, Begineer, Advanced Bootcamp sessions, Zero to Hero in programming"
  );
  useEffect(() => {
    handleReSize();
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function getData() {
      let data = await service.getCoursesPills({ signal: signal });
      if (data.status === 204) {
        setCourseCategory([]);
      } else {
        setCourseCategory(data);
        //set keywords based on the course categories available
        let tempWords = keyWords.split(", ");
        if (data.length > 0) {
          data.forEach((course) => tempWords.push(course));
        }
        setKeyWords(tempWords.join(", "));
      }

      setIsLoading(false);
    }
    getData();
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleReSize = () => {
    if (window.innerWidth <= 762) {
      setMinPillsCount(3);
    } else {
      setMinPillsCount(5);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleReSize);
    return () => {
      window.removeEventListener("resize", handleReSize);
    };
  });
  function CourseCategoryMap({ data }) {
    var courseData = forLoadMore(data, loadMore, minPillsCount);
    if (Array.isArray(courseData)) {
      if (courseData.length > 0) {
        return courseData.map((category, index) => {
          return (
            <li key={index} id="courses-header-course-category">
              <a
                href="#all-courses"
                className="course-pills-bg"
                onClick={() => loadCourse(category)}
              >
                {category}
              </a>
            </li>
          );
        });
      }
    }
    return null;
  }

  const loadCourse = (name) => {
    gaEventTracker(`Course with Category ${name} is Clicked by the User`);
    setCurrentCategory(name);
  };

  const loadAllCourses = () => {
    setLoadMore(true);
  };

  const showLessCourses = () => {
    setLoadMore(false);
  };

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="UTF-8" />
          <meta name="keywords" content={keyWords} />
        </Helmet>
      </div>
      <div className="container">
        <div className="d-flex">
          <div className="course-category-header">
            <h2 className="header-style" id="courses-header-title">
              Course Category
            </h2>
            <div className="subtitle">
              <h5 className="header-subtile" id="courses-header-subtitle">
                What is your interest ?
              </h5>
              <ul
                className="flex-wrap navpills d-flex"
                data-category={currentCategory}
              >
                {isLoading ? (
                  [1, 2, 3, 4].map((nos) => <SkeletonHeader key={nos} />)
                ) : (
                  <Fragment>
                    <CourseCategoryMap data={courseCategory} />
                    {courseCategory.length > minPillsCount ? (
                      !loadMore ? (
                        <li key={minPillsCount + 1}>
                          <button
                            className="load-button btn btn-sm"
                            onClick={loadAllCourses}
                          >
                            ...More
                          </button>
                        </li>
                      ) : (
                        <li key={courseCategory.length}>
                          <button
                            className="btn btn-sm load-button"
                            onClick={showLessCourses}
                          >
                            ...Less
                          </button>
                        </li>
                      )
                    ) : null}
                  </Fragment>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseHeader;
