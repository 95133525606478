import React from "react";
import { Modal } from "react-bootstrap";
import "./policiesStyles.css";
function TermsAndConditions(props) {
  return (
    <Modal
      className="policy-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="query-form-modal"
    >
      <Modal.Header closeButton>
        <div className="terms-policy-title w-100 d-flex justify-content-center">
          <h4 className="terms-conditions-title">Terms and Conditions</h4>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
      >
        <article className="terms-conditions-content">
          <p>
            These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, Rajala's Tech Academy
            accessible at{" "}
            <a className="policy-mail-link" href="https://rajalas-academy.com/">
              https://rajalas-academy.com/
            </a>
          </p>
          <p>
            These Terms and Conditions set out the rights and obligations of all
            users regarding the use of the Service. Your access to and use of
            the Service is conditioned on your acceptance of and compliance with
            these Terms and Conditions. These Terms and Conditions apply to all
            visitors, users and others who access or use the Service. By
            accessing or using the Service you agree to be bound by these Terms
            and Conditions. If you disagree with any part of these Terms and
            Conditions then you may not access the Service. Your access to and
            use of the Service is also conditioned on your acceptance of and
            compliance with the Privacy Policy of the Company. Our Privacy
            Policy describes Our policies and procedures on the collection, use
            and disclosure of your personal information when you use the
            Application or the Website and tells you about your privacy rights
            and how the law protects you. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>
          <h5 className="policy-titles">Your Account in Website</h5>
          <p>
            In order to access our Resources, you may be required to provide
            certain information about yourself (such as name, email, phone
            number, contact details, etc.) as part of the registration process,
            or as part of your ability to use the Resources. You are solely
            responsible any consequences, losses, or damages that we may
            directly or indirectly incur or suffer due to any unauthorized
            activities conducted by you, as explained above, and may incur
            criminal or civil liability.
          </p>
          <h5 className="policy-titles">Links to another website</h5>
          <p>
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company. The Company has no
            control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third-party web sites or
            services. You further acknowledge and agree that the Company shall
            not be responsible or liable, directly or indirectly, for any damage
            or loss caused or alleged to be caused by or in connection with the
            use of or reliance on any such content, goods or services available
            on or through any such web sites or services.
          </p>
          <h5 className="policy-titles">
            Responsibilities of Website Visitors
          </h5>
          <p>
            By operating the Website, Our Website does not represent or imply
            that it endorses the material there posted, or that it believes such
            material to be accurate, useful or non-harmful. You are responsible
            for taking precautions as necessary to protect yourself and your
            computer systems from viruses, worms, Trojan horses, and other
            harmful or destructive content. The Website may contain content that
            is offensive, indecent, or otherwise objectionable, as well as
            content containing technical inaccuracies, typographical mistakes,
            and other errors. The Website may also contain material that
            violates the privacy or publicity rights, or infringes the
            intellectual property and other proprietary rights, of third
            parties, or the downloading, copying or use of which is subject to
            additional terms and conditions, stated or unstated. We disclaim any
            responsibility for any harm resulting from the use by visitors of
            the Website, or from any downloading by those visitors of content
            there posted.
          </p>
          <p>
            By posting information or otherwise using any open communication
            tools as mentioned, you agree that you will not upload, post, share,
            or otherwise distribute any content that:
          </p>
          <ul className="responsibility-warranty-lists">
            <li>
              Is illegal, threatening, defamatory, abusive, harassing,
              degrading, intimidating, fraudulent, deceptive, invasive, racist,
              or contains any type of suggestive, inappropriate, or explicit
              language;
            </li>
            <li>
              Infringes on any trademark, patent, trade secret, copyright, or
              other proprietary right of any party;
            </li>
            <li>
              Contains any type of unauthorized or unsolicited advertising;
            </li>
            <li>
              Impersonates any person or entity, including any our employees or
              representatives.
            </li>
          </ul>
          <h5 className="policy-titles">Limitation of warranty</h5>
          <p>
            The Service is provided to you "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. Without
            limiting the foregoing, neither the Company/Website nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied:
          </p>
          <ul className="responsibility-warranty-lists">
            <li>
              as to the operation or availability of the Service, or the
              information, content, and materials or products included thereon;
            </li>
            <li>that the Service will be uninterrupted or error-free;</li>
            <li>
              as to the accuracy, reliability, or currency of any information or
              content provided through the Service; or
            </li>
            <li>
              that the Service, its servers, the content, or e-mails sent from
              or on behalf of the Company are free of viruses, scripts, trojan
              horses, worms, malware, timebombs or other harmful components.
            </li>
            <li>
              Any direct, indirect, incidental, consequential or exemplary loss
              or damages which may be incurred by you as a result of using our
              Resources, or as a result of any changes, data loss or corruption,
              cancellation, loss of access, or downtime to the full extent that
              applicable limitation of liability laws apply.
            </li>
          </ul>
          <h5 className="policy-titles">Limitation of liability</h5>
          <p>
            In no event shall Rajala's Tech Academy, nor its director, officers
            and employees, shall be held liable for any indirect, consequential
            or special liability arising out or in any way connected with your
            use of this website whether such liability is under contract.
          </p>
          <h5 className="policy-titles">Intellectual Property Rights </h5>
          <p>
            Other than content you own, under these Terms, you are not allowed
            to use or reproduce the registered logo, graphics, all trademarks
            and all the materials which are in connection with this website. You
            are granted limited license only for purposes of viewing the
            material contained on this website. Attempting to copy, duplicate,
            reproduce, sell, trade, or resell our resources is strictly
            prohibited.
          </p>
          <h5 className="policy-titles">Advertisement</h5>
          <p>
            Advertisements are displayed in our website unless you have
            purchased an ad-free account.
          </p>
          <h5 className="policy-titles">Termination</h5>
          <p>
            We may terminate or suspend your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach these Terms and Conditions. Upon
            termination, your right to use the Service will cease immediately.
          </p>
          <h5 className="policy-titles">Indemnification</h5>
          <p>
            you hereby indemnify to the fullest extent Rajala's Tech Academy
            from and against any and/or all liabilities, costs, demands, causes
            of action, damages and expenses arising in any way related to your
            breach of any of the provisions of these Terms.
          </p>
          <h5 className="policy-titles">Updates to this policy</h5>
          <p>
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. What constitutes a material change will be
            determined at Our sole discretion. By continuing to access or use
            Our Service after those revisions become effective, you agree to be
            bound by the revised terms. If you do not agree to the new terms, in
            whole or in part, please stop using the website and the Service.
          </p>
          <h5 className="policy-titles">Contact Us</h5>
          <p>
            If you have any questions about these Terms and Conditions, you can
            contact us:
          </p>
          <div className="email-style">
            By Email
            <span className="terms-email-separator">:</span>
            <a
              className="policy-mail-link"
              rel="noopener noreferrer"
              href="mailto:support@rajalas-academy.com"
            >
              support@rajalas-academy.com
            </a>
          </div>
          <div className="email-style">
            By Phone
            <span className="terms-contact-separator">:</span>
            <a
              className="policy-mail-link"
              href="tel:+917729852396"
              rel="noopener noreferrer"
            >
              +917729852396
            </a>
          </div>
        </article>
      </Modal.Body>
      <Modal.Footer>
        <button className="close-button" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default TermsAndConditions;
