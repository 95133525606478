import React, { useContext, useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import service from "../../../service";
import { ModalContext } from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "react-phone-input-2/lib/style.css";
import "./miniQuery.css";

function MiniQuery(props) {
  const [isDiscount, setIsDiscount] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const [errormessage, setErrorMessage] = useState({
    nameErrorMessage: "",
    emailErrorMessage: "",
    mobileErrorMessage: "",
    queryMessageErrorMessage: "",
  });
  const [queryformInput, setQueryformInput] = useState({
    formName: "",
    formEmail: "",
    formMobile: "",
    formMessage: "",
    typeOfRequest: "query",
  });
  const [phoneNo] = useState("");
  const handleChangePhone = (phone) => {
    setQueryformInput({ ...queryformInput, formMobile: phone });
  };
  const handleInputData = (e) => {
    if (!isEditing) {
      setIsEditing(true);
    }
    let name = e.target.name;
    let value = e.target.value;
    setQueryformInput({
      ...queryformInput,
      [name]: value,
    });
  };

  const formValidation = () => {
    let name = "";
    let email = "";
    let mobile = "";
    let {
      nameErrorMessage,
      emailErrorMessage,
      mobileErrorMessage,
    } = errormessage;
    let emailregex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let nameregex = /^[A-Za-z ]{1,}$/;
    let { formName, formEmail, formMobile } = queryformInput;
    if (formName === "") {
      name = "Name is required";
    } else if (formName <= 2) {
      name = "Name should not be less than 3 characters";
    } else if (formName.match(nameregex) === null) {
      name = "Name should contain alphabets only";
    } else {
      name = "";
    }
    if (formEmail === "") {
      email = "Email ID is required";
    } else if (formEmail.match(emailregex) === null) {
      email = "Please enter a valid Email ID";
    } else {
      email = "";
    }
    if (formMobile === "") {
      mobile = "Mobile Number is required";
    } else if (formMobile.length < 7) {
      mobile = "Please enter a valid number";
    } else {
      mobile = "";
    }
    setErrorMessage({
      emailErrorMessage: email,
      nameErrorMessage: name,
      mobileErrorMessage: mobile,
    });
    if (
      nameErrorMessage === "" &&
      emailErrorMessage === "" &&
      mobileErrorMessage === "" &&
      formName !== "" &&
      formEmail !== "" &&
      formMobile !== ""
    ) {
      //backend call with "queryformInput state object"//
      queryDataSubmit();
    } else {
      return;
    }
  };

  const handleSubmitEvent = (e) => {
    e.preventDefault();
  };

  async function queryDataSubmit() {
    let queryResponse = await service.queryFormSubmit(queryformInput);
    if (queryResponse.status === 500) {
      setResponseModal({
        ...responseModal,
        queryModal: false,
        reqDemoModal: false,
        errorModal: true,
      });
    } else {
      setResponseModal({
        ...responseModal,
        queryModal: false,
        reqDemoModal: false,
        successModal: true,
      });
    }
  }

  useEffect(() => {
    if (props.discount !== undefined) {
      setIsDiscount(props.discount.enabled);
    } else {
      setIsDiscount(false);
    }
  }, [props]);

  useEffect(() => {
    function handleResize() {
      if (isDiscount) {
        // Need to modify once modifying the mongodb schema

        if (window.pageYOffset < 500) {
          setIsSticky(false);
        } else {
          setIsSticky(true);
        }
      } else {
        if (window.pageYOffset < 245) {
          setIsSticky(false);
        } else {
          setIsSticky(true);
        }
      }
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom + 300 >= docHeight) {
        setIsBottom(true);
        setIsSticky(false);
      } else {
        setIsBottom(false);
      }
    }
    window.addEventListener("scroll", handleResize);

    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, [isDiscount]);
  return (
    <section
      className={
        isSticky
          ? "mini-query-section beSticky"
          : isBottom
          ? "mini-query-section absolute"
          : "mini-query-section"
      }
    >
      <form onSubmit={handleSubmitEvent} autoComplete="off">
        <h3 className="mini-query-header" id="coursedetail-miniquery-header">
          Have Any Query?
        </h3>
        <div className="mini-query-fields">
          <input
            placeholder="Name*"
            className="mini-query-input"
            type="text"
            name="formName"
            id="coursedetail-miniquery-name"
            onInput={() => setIsEditing(true)}
            onChange={handleInputData}
          />
        </div>
        <div className="error-message" id="coursedetail-miniquery-name-error">
          {errormessage.nameErrorMessage}
        </div>
        <div className="mini-query-fields">
          <input
            placeholder="E-mail*"
            className="mini-query-input"
            type="text"
            name="formEmail"
            id="coursedetail-miniquery-email"
            onInput={() => setIsEditing(true)}
            onChange={handleInputData}
          />
        </div>
        <div className="error-message" id="coursedetail-miniquery-email-error">
          {errormessage.emailErrorMessage}
        </div>
        <div className="mini-query-fields mb-2">
          <PhoneInput
            country={"in"}
            value={phoneNo}
            name="formMobile"
            className=" react-tel-input"
            id="coursedetail-miniquery-phone"
            onChange={(phoneNo) => handleChangePhone(phoneNo)}
            onInput={() => setIsEditing(true)}
            inputStyle={{ width: "100%" }}
          />
        </div>
        <div className="error-message" id="coursedetail-miniquery-phone-error">
          {errormessage.mobileErrorMessage}
        </div>
        <div className="mini-query-textarea mt-3">
          <textarea
            className="mini-query-text-box"
            placeholder="Your Message"
            name="formMessage"
            id="coursedetail-miniquery-textarea"
            onInput={() => setIsEditing(true)}
            onChange={handleInputData}
          />
        </div>
        <div className="d-flex p-2 justify-content-end mt-2">
          <button
            className="mini-query-submit-btn"
            id="coursedetail-miniquery-submit-btn"
            type="button"
            onClick={formValidation}
          >
            Submit
            <span className="mini-query-sendicon">
              <FontAwesomeIcon icon={faPaperPlane} />
            </span>
          </button>
        </div>
      </form>
    </section>
  );
}
export default MiniQuery;
