import React, { useState, useEffect } from "react";
import "./CourseHeader.css";
import StarRatings from "react-star-ratings";
function CourseDetailedHeader(props) {
  let { data } = props;
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountRate, setDiscountRate] = useState("0%");
  const [duration, setDuration] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
  });
  useEffect(() => {}, [props]);

  useEffect(() => {
    let { discount, duration } = props.data;
    if (discount !== undefined) {
      // Need to modify once modifying the mongodb schema
      setShowDiscount(discount.enabled);
      setDiscountRate(discount.rate);
    }
    if (duration !== undefined) {
      let _duration = { ...duration };
      if (parseInt(_duration.days) < 10) {
        _duration.days = "0" + _duration.days;
      }
      if (parseInt(_duration.hours) < 10) {
        _duration.hours = "0" + _duration.hours;
      }
      setDuration(_duration);
    }
  }, [props]);

  return (
    <React.Fragment>
      {showDiscount ? (
        <div className="d-xl-none">
          <section className="discount-banner">
            <span className="text">
              Special Offer{" "}
              <strong className="discount-rate" id="discount-rate">
                {discountRate}
                <small>%</small>
              </strong>{" "}
              Discount.
              <span role="img" aria-labelledby="happy-face">
                &#128521;
              </span>{" "}
              Grab this great oppurtunity!!
            </span>
          </section>
        </div>
      ) : null}

      <h1 className="course-detail-title" id="course-detail-title">
        {data.course_name}
      </h1>
      <p className="course-detail-content" id="course-detail-content">
        {data.summary}
      </p>
      <span className="course-detail-rating" id="course-detail-rating">
        <span>{parseFloat(data.rating)}</span>
        <StarRatings
          rating={parseFloat(data.rating)}
          starRatedColor="#ffca08"
          numberOfStars={5}
          name="rating"
          starDimension="18px"
          starSpacing={"2px"}
        />
      </span>

      <div className="d-xl-none">
        <p className="duration-sm" id="course-duration-sm">
          <label htmlFor="course-duration-sm">Duration : </label>

          <span>
            <strong>{duration.days}</strong>Days
          </span>

          <span>
            <strong>{duration.hours}</strong>Hours
          </span>
        </p>
      </div>
    </React.Fragment>
  );
}
export default CourseDetailedHeader;
