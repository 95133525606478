import React from "react";
import QueryForm from "../../../components/queryForm/queryForm";
function QueryFormSection() {
  return (
    <React.Fragment>
      <div className="justify-content-center col-md-12 col-sm-12 card-section-query" width="100%">
        <h3 className="query-section-heading mb-4" id="contact-us-queryform">Send us a message</h3>
        <div className="query-form">
          <QueryForm type="contactUs-Query" />
        </div>
      </div>
    </React.Fragment>
  )
}
export default QueryFormSection;