import React, { useState, useEffect } from "react";
import LaptopManwithTimeGlassBG from "../../../assets/images/duration.png";
import "./durationBox.css";

function DurationBox(props) {
  const [duration, setDuration] = useState({
    hours: "00",
  });
  useEffect(() => {
    if (props.duration !== undefined) {
      let _duration = { ...props.duration };
      // if (parseInt(_duration.days) < 10) {
      //   _duration.days = "0" + _duration.days;
      // }
      if (parseInt(_duration.hours) < 10) {
        _duration.hours = "0" + _duration.hours;
      }
      setDuration(_duration);
    }
  }, [props]);
  return (
    <section className="duration-box">
      <img
        src={LaptopManwithTimeGlassBG}
        alt="duration of this course"
        className="man-with-hourglass"
      />
      {/* <div className="duration-specifier-wrap">
        <ul className="course-duration-container" id="duration-data">
          <li>
            <h2>{duration.days}</h2>
            <small>Days</small>
          </li>
          <li>
            <h2>{duration.hours}</h2>
            <small>Hrs</small>
          </li>
        </ul>
      </div> */}
      <div className="duration-specifier-wrap">
        <h3>Duration</h3>
        <h2>{duration.hours}</h2>
        <small>Hours</small>
      </div>
    </section>
  );
}
export default DurationBox;
