import React from "react";
import { Modal } from "react-bootstrap";
import "./policiesStyles.css";
function PolicyModal(props) {
  return (
    <Modal
      className="policy-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="query-form-modal"
    >
      <Modal.Header closeButton>
        <div className="private-policy-title w-100 d-flex justify-content-center">
          <h4 className="privacy-title">Privacy Policy</h4>
        </div>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
      >
        <article className="privacy-policy-content">
          <p>
            This Privacy Policy describes our policies and procedures on the
            collection, use and disclosure of your information when you use the
            Service and tells you about your privacy rights and how the law
            protects you.We use your Personal data to provide and improve the
            Service. By using the Service, you agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
          <h5 className="policy-titles">Types of Data Collected</h5>
          <ol className="sub-content">
            <li className="data-header">
              <h6 className="data-types-title">Personal Data</h6>
              <ul>
                <p className="data-collected-content">
                  {" "}
                  While using our Service, we may ask you to provide Us with
                  certain personally identifiable information that can be used
                  to contact or identify you. Personally, identifiable
                  information may include, but is not limited to:
                </p>
                <li className="personal-data-list">Name</li>
                <li className="personal-data-list">Email Address</li>

                <li className="personal-data-list">Phone Number</li>
              </ul>
            </li>

            <li className="data-header">
              <h6> Usage Data</h6>
              <p className="data-collected-content">
                Usage Data is collected automatically when using the Service.
                Usage Data may include information such as your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p className="data-collected-content">
                When you access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device you use, your mobile
                device unique ID, the IP address of your mobile device, your
                mobile operating system, the type of mobile Internet browser you
                use, unique device identifiers and other diagnostic data. We may
                also collect information that your browser sends whenever you
                visit our Service or when you access the Service by or through a
                mobile device.
              </p>
            </li>
          </ol>
          <h5 className="policy-titles">Tracking Technologies and Cookies</h5>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyse our Service. You can
            instruct your browser to refuse all Cookies or to indicate when a
            Cookie is being sent. However, if you do not accept Cookies, you may
            not be able to use some parts of our Service.
          </p>
          <h5 className="policy-titles">Uses of Personal Data</h5>

          <ul>
            <p>The Company may use Personal Data for the following purposes:</p>
            <li className="personal-data-list">
              To provide and maintain our Service, including to monitor the
              usage of our Service.
            </li>
            <li className="personal-data-list">
              To manage your Account: to manage your registration as a user of
              the Service. The Personal Data you provide can give you access to
              different functionalities of the Service that are available to you
              as a registered user.
            </li>
            <li className="personal-data-list">
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services you have purchased or of any other contract with Us
              through the Service.
            </li>
            <li className="personal-data-list">
              To contact you: To contact you by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li className="personal-data-list">
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information.
            </li>
            <li className="personal-data-list">
              To manage your requests: To attend and manage your requests to Us.
            </li>
            <li className="personal-data-list">
              Those who engage in transactions with Website are asked to provide
              additional information, including as necessary the personal and
              financial information required to process those transactions.
            </li>
          </ul>
          <ul>
            <p>
              We may share your personal information in the following
              situations:
            </p>

            <li className="personal-data-list">
              With Service Providers: We may share your personal information
              with Service Providers to monitor and analyse the use of our
              Service, to show advertisements to you to help support and
              maintain our Service, to contact you, to advertise on third party
              websites to you after you visited our Service or for payment
              processing.
            </li>
            <li className="personal-data-list">
              For Business transfers: We may share or transfer your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li className="personal-data-list">
              With Affiliates: We may share your information with our
              affiliates, in which case we will require those affiliates to
              honour this Privacy Policy. Affiliates include our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li className="personal-data-list">
              With Business partners: We may share your information with our
              business partners to offer you certain services or promotions.
            </li>
          </ul>
          <h5 className="policy-titles">Business Transfers</h5>
          <p>
            our Service may contain links to other websites that are not
            operated by us. If you click on a third-party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy of every site you visit. We have no
            control over and assume no responsibility for the content, privacy
            policies or practices of any third-party sites or services.
          </p>
          <h5 className="policy-titles">Ads</h5>
          <p>
            Ads appearing on any of our websites may be delivered to users by
            advertising partners, who may set cookies. These cookies allow the
            ad server to recognize your computer each time they send you an
            online advertisement to compile information about you or others who
            use your computer. This information allows ad networks to, among
            other things, deliver targeted advertisements that they believe will
            be of most interest to you. This Privacy Policy covers the use of
            cookies by Rajala's Tech Academy and does not cover the use of
            cookies by any advertisers.
          </p>
          <h5 className="policy-titles">Updates to Privacy Policy</h5>
          <p>
            We may update our Privacy Policy from time to time. You are advised
            to review this Privacy Policy periodically for any changes. If you
            have account in this website, then you will receive an alert
            regarding the changes to this policy.
          </p>
          <h5 className="policy-titles">Refund policy</h5>
          <p>
            We take pride in the services delivered by us and guarantee your
            satisfaction with our services and support. We constantly improve
            and strive to deliver the best accounting, financial or secretarial
            services through the internet. However, in case you are not
            satisfied with our services, please contact us immediately and we
            will correct the situation, provide a refund or offer credit that
            can be used for future. You can attend the live demo or watch the
            demo videos before the registration of the courses based on the
            request. If you are interested to attend the training, you should
            pay the course fee. We are providing you two options for payment of
            fees. Either you can pay full payment or 50% payment before the
            training commencement. Once training reached to 40%, remaining 50%
            of the fees will be collected by us.
          </p>
          <h5 className="sub-content">Guaranteed Money Refund</h5>
          <p className="sub-content-paragragh">
            While attending the training, if you feel dissatisfied, you can
            apply/request for refund to{" "}
            <a
              className="policy-mail-link"
              rel="noopener noreferrer"
              href="mailto:info@rajalas-academy.com"
            >
              info@rajalas-academy.com
            </a>{" "}
            before the end of 3rd session of training or before attending the
            training for 100% refund. If you request after attended more than 3
            sessions, some charges will be deducted from the fee amount based on
            the number of sessions attended and the remaining amount will be
            refunded or we will provide you an offer to attend other batches
            instead of refund.
          </p>
        </article>
      </Modal.Body>
      <Modal.Footer>
        <button className="close-button" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default PolicyModal;
