import React from "react";
function TopSection() {
  return (
    <React.Fragment>
      <div className="top-section-contact">
        <h1 className="top-contact-heading" id="contactus-headersection-header">
          Hello{" "}
          <span role="img" aria-labelledby="hello wave">
            &#128522;
          </span>
          , how can we help you?
        </h1>
        <p className="top-contact-content" id="contactus-headersection-content">
          We are here to help and answer any questions you might have.We look
          forward to hear from you.
        </p>
      </div>
    </React.Fragment>
  );
}
export default TopSection;
