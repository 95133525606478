import React from "react";
import ContactUsImage from "../../../assets/images/contactus_top.png";
function RelativeImage() {
  return (
    <div className="fade-in">
      <img
        src={ContactUsImage}
        className="top-contact-image"
        alt="contact our support"
        id="contactus-headersection-iamge"
      />
    </div>
  );
}
export default RelativeImage;
