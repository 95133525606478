export const sortArray = (Course, type, isAscending) => {
  const types = {
    rating: "rating",
    created_on: "created_on",
    course_name: "course_name",
  };
  var sorted = "";
  const sortProperty = types[type];
  if (sortProperty === "rating") {
    sorted = [...Course].sort((a, b) => b[sortProperty] - a[sortProperty]);
  } else if (sortProperty === "created_on") {
    sorted = [...Course].sort((a, b) => {
      var dateA = new Date(a.created_on);
      var dateB = new Date(b.created_on);
      return dateB - dateA;
    });
  } else if (sortProperty === "course_name" && isAscending) {
    sorted = [...Course].sort((a, b) => {
      var nameA = a.course_name.toLowerCase();
      var nameB = b.course_name.toLowerCase();
      return nameA > nameB ? 1 : -1;
    });
  } else if (sortProperty === "course_name" && !isAscending) {
    sorted = [...Course].sort((a, b) => {
      var nameA = a.course_name.toLowerCase();
      var nameB = b.course_name.toLowerCase();
      return nameA < nameB ? 1 : -1;
    });
  }
  return sorted;
};
