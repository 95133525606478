import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonGridCard() {
  return (
    <SkeletonTheme color="#f2f2f2" highlightColor="rgb(255, 255, 255)">
      <h4 className="course-detail-title">
        <Skeleton height={35} width={"50%"} />
      </h4>
      <p className="course-detail-content">
        <Skeleton height={100} width={"100%"} />
      </p>
      <div className="course-detail-rating">
        <Skeleton height={30} width={"30%"} />
      </div>
    </SkeletonTheme>
  );
}

export default SkeletonGridCard;
