import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

function SkeletonTestimonial() {
  return (
    <SkeletonTheme color="#e6e6e6" highlightColor="rgb(255, 255, 255)">
      <div className="d-flex flex-column align-items-center carousel-item">
        <div className="testimonial-container px-2">
          <div className="d-flex align-items-center justify-content-center">
            <Skeleton circle={true} height={100} width={100} />
          </div>
          <div className="testimonial-content">
            <h2 className="feedback-title">
              <Skeleton height={28} width={200} />
            </h2>
            <p className="client-feedback">
              <FontAwesomeIcon
                icon={faQuoteLeft}
                size="2x"
                className="quote-left"
              />
              <span>
                <Skeleton height={100} width={`100%`} />
              </span>
            </p>
            <h2 className="client-name">
              <Skeleton height={28} width={100} />
            </h2>
            <small className="client-designation">
              <Skeleton height={28} width={200} />
            </small>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default SkeletonTestimonial;
