import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../../App";
import thankYou from "../../assets/images/success.png";
import bell from "../../assets/images/bell.png";
import "./thankYouModal.css";

function ThankYouModal(props) {
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOpenModal(responseModal.successModal);

    return () => {};
  }, [responseModal]);

  const closeModal = () => {
    setResponseModal({
      ...responseModal,
      successModal: false,
      errorModal: false,
    });
  };

  return (
    <>
      {openModal ? (
        <div className="bg-thankyou-modal" onClick={closeModal}>
          <div className="thankyou-modal-content">
            <div className="thankyou-header">
              <button
                type="button"
                className="button-close"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true" className="close-btn">
                  &times;
                </span>
              </button>
            </div>
            <div>
              <img
                src={thankYou}
                alt="thank you for sending request"
                className="success-img"
              />
              <img src={bell} alt="ringing bell" className="bell-img" />
            </div>
            <h2 className="thankyou-text">THANK YOU</h2>
            <p className="msg-content">
              YOUR SUBMISSION HAS BEEN RECEIVED. WE WILL BE IN TOUCH AND CONTACT
              YOU SOON{" "}
            </p>
            <div className="mb-4">
              <a
                className="thankYou-close"
                href="/courses"
                onClick={closeModal}
              >
                Explore More
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default ThankYouModal;
