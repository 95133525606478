import React, { Fragment, useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseDetailHeader from "./header/CourseDetailHeader";
import VideoPlayer from "./VideoPlayer/videoPlayer";
import CourseContent from "./courseContent/courseContent";
import DiscountBanner from "./discountBanner/discountBanner";
import DurationBox from "./durationBox/durationBox";
import MiniQuery from "./miniQuery/miniQuery";
// import FAQ from "./faq/faq";
// import FAQImage from "./faq/faqImage";
import ModesOfTraining from "./trainingModes/modesOfTraining";
import UpcomingEvents from "./upcomingEvents/upcomingEvents";
import RelatedCourses from "./relatedCourses/relatedCourses";
import SkeletonHeader from "./header/headerSkeleton";
import service from "../../service";
import "./courseDetailIndex.css";

function Course() {
  const [isLoading, setIsLoading] = useState(true);
  const [courseData, setCourseData] = useState({});
  // const [courseID, setCourseID] = useState("");
  const [URLInfo, setURLInfo] = useState({
    courseName: "",
    courseID: "",
  });
  let loc = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  useEffect(() => {
    //backend call for course details fetching

    let courseName = query.get("courseName");
    let courseID = query.get("id");
    if (courseName !== URLInfo.courseName && courseID !== URLInfo.courseID) {
      window.scrollTo(0, 0);
      setURLInfo({ courseName, courseID });
      // setCourseID(courseID);
      async function getCourseData() {
        let data = await service.getCourseDetails(courseID);
        if (data.status === 204) {
          setCourseData(data.report); //No Content
        } else {
          setCourseData(data);
        }
        setIsLoading(false);
      }
      getCourseData();
    }
    return () => {};
  }, [loc, query, URLInfo]);
  if (courseData === "No Content") {
    return <Redirect to="/*" />;
  }
  return (
    <Fragment>
      {Object.keys(courseData).length > 0 ? (
        <Helmet>
          <meta charSet="UTF-8" />
          <title>{courseData.course_name} | Rajala's Tech Academy</title>
          <meta name="description" content={courseData.summary} />
          <meta name="keywords" content={courseData.course_name} />
        </Helmet>
      ) : null}
      <section className="course-detail-main container-fluid">
        <div className="left-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div id="course-header" className="detail-header">
                  {!isLoading ? (
                    <CourseDetailHeader data={courseData} />
                  ) : (
                    <SkeletonHeader />
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <VideoPlayer data={courseData} />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div
                  className="course-content-section"
                  id="coursedetail-coursecontent-section"
                >
                  <CourseContent data={courseData} />
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div
                  id="coursedetail-modes-training-section"
                  className="modes-training-section"
                >
                  <ModesOfTraining  coursename={courseData.course_name} />
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div
                  id="coursedetail-upcoming-events-section"
                  className="upcoming-events-section"
                >
                  <UpcomingEvents
                    courseid={courseData._id}
                    coursename={courseData.course_name}
                  />
                </div>
              </div>
              {/* <div className="container-fluid pb-5">
                <div className="row px-3">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div id="coursedetail-faq-section" className="faq-section">
                      <FAQ />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div
                      id="coursedetail-faq-image"
                      className="faq-image-section"
                    >
                      <FAQImage />
                    </div>
                  </div>
                </div>
              </div> */}
              <RelatedCourses data={courseData.course_category} />
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="wrapper">
            <div className="d-flex flex-column px-3 my-3 w-100">
              <DiscountBanner discount_rate={courseData.discount} />
              <DurationBox duration={courseData.duration} />
              <MiniQuery discount={courseData.discount} />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Course;
