import "./coursesStyles.css";
import React, { createContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CourseHeader from "./header/courseHeader";
import PopularCourses from "../../components/popularCourses/popularCoursesCards";
import AllCourses from "./allCourses/allCourses";

export const CourseContext = createContext();

export const CourseContextProvider = (props) => {
  const [currentCategory, setCurrentCategory] = useState("");

  return (
    <CourseContext.Provider value={[currentCategory, setCurrentCategory]}>
      {props.children}
    </CourseContext.Provider>
  );
};

function Courses() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="UTF-8" />
          <title>Courses | Rajala's Tech Academy</title>
          <meta
            name="keywords"
            content="in demand programming languages, deep learning, training on flexible timing, online courses for computer skills,online training courses with certificates
full stack developer bootcamp on,
online computer programming certificate courses,
Software skills training,
Best programming skills to know 2020,
best programming language to learn,
free demo session
"
          />
          <meta
            name="description"
            content="An online library, Here you can find all your learning requirements and improve your overall skills and knowledge by choosing the courses relevant to your field"
          />
        </Helmet>
      </div>
      <CourseContextProvider>
        <div
          className="oep-course-section courses-header-container mb-3"
          id="courses-header-section"
        >
          <CourseHeader />
        </div>
        <div className="oep-course-section">
          <div className="container">
            <h2
              className="courses-section-title"
              id="courses-popular-courses-title"
            >
              Popular Courses
            </h2>
            <div className="pop-wrapper" id="courses-popular-courses-section">
              <PopularCourses />
            </div>
          </div>
        </div>
        <div className="oep-course-section" id="all-courses">
          <div className="container">
            <div className="mb-5">
              <h2
                className="courses-section-title"
                id="courses-all-courses-title"
              >
                All Courses
              </h2>
            </div>
            <AllCourses />
          </div>
        </div>
      </CourseContextProvider>
    </>
  );
}
export default Courses;
