import React from "react";
import QueryForm from "../../../components/queryForm/queryForm";
function QueryFormSection() {
  return (
    <React.Fragment>
      <h2 className="query-section-title" id="home-queryform-header">Have a Query?</h2>
      <div className="justify-content-center col-md-12 col-sm-12 card-section" width="100%" height="250px">
        <div className="query-form" id="home-queryform">
          <QueryForm type="query" />
        </div>
      </div>
    </React.Fragment>
  )
}
export default QueryFormSection;