import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import ModalView from "../modalView";
import { ModalContext } from "../../App";
import "./BeATuTor.css";
function BecomeATuTor() {
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const [modalShow, setModalShow] = useState(false);
  const openModal = () => {
    setModalShow(true);

    setResponseModal({ ...responseModal, beTutorModal: true });
  };
  const closeModal = () => {
    setModalShow(false);
    setResponseModal({
      ...responseModal,
      beTutorModal: false,
    });
  };

  return (
    <>
      <button
        className="be-tutor-fbtn"
        onClick={openModal}
        title="To become a tutor"
      >
        <FontAwesomeIcon
          icon={faChalkboardTeacher}
          style={{ marginRight: 5 }}
        />{" "}
        Join Us
      </button>
      <ModalView type={"Become-tutor"} show={modalShow} onHide={closeModal} />
    </>
  );
}
export default BecomeATuTor;
