import React, { Fragment } from "react";
import Experts from "../../../assets/images/experts.png";
import CourseCustomize from "../../../assets/images/courseCustomize.png";
import RealTime from "../../../assets/images/realtime.png";
import Recordings from "../../../assets/images/recordings.png";
import JobReady from "../../../assets/images/jobready.png";
import "./keyFeaturesStyles.css";

function KeyFeatures(props) {
  const keyFeaturesData = [
    {
      imgSrc: Experts,
      title: "Expert Trainers",
      description:
        "Our Trainers are well experienced having 10+ years real time experience. Clients will get 100% real-time thoughts [like project planning, architecture designs, etc..] and problem solving skills from experts.",
    },
    {
      imgSrc: CourseCustomize,
      title: "Course Customization",
      description:
        "Our expert trainers assist our clients in creating customized learning plans. Key areas to focus in project development and easy ways to learn and prepare for interviews.",
    },
    {
      imgSrc: RealTime,
      title: "Real-time Project",
      description:
        "Gain real-time project experience and profound knowledge by involving in hands on projects and get hired quickly.",
    },

    {
      imgSrc: Recordings,
      title: "Session Recordings and Materials",
      description:
        "Get session by session records. Will provide Users will have access to take training materials and project code. On successful course completion users will get course completion certificates.",
    },
    {
      imgSrc: JobReady,
      title: "Job readiness",
      description:
        "Our good experienced trainers will guide you to prepare resumes and required interview questions related to the course. Trainers will conduct some mock-up interviews which helps you to face interviews without hesitation.",
    },
  ];
  return (
    <Fragment>
      <div className="rel-container">
        <div className="container-fluid">
          <div className="section-title-container d-flex justify-content-center mb-5">
            <h2 className="section-title" id="home-key-features-title">
              Key Features
            </h2>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {keyFeaturesData.map((feature, index) => (
              <div key={index + 1} title={feature.title}>
                <Card data={feature} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function Card(props) {
  return (
    <div className="keyfeature-card">
      <div
        style={{ height: "170px" }}
        className="d-flex justify-content-center align-items-center"
      >
        <img
          src={props.data.imgSrc}
          alt={props.data.title}
          className="keyfeature-image"
        />
      </div>

      <h4 className="keyfeature-card-title">{props.data.title}</h4>
      <p className="keyfeature-description">{props.data.description}</p>
    </div>
  );
}

export default KeyFeatures;
