import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonCourseContent() {
  return (
    <SkeletonTheme color="#f2f2f2" highlightColor="rgb(255, 255, 255)">
      <div style={{ marginBottom: "7px" }}>
        <Skeleton height={50} width={`100%`} />
      </div>
    </SkeletonTheme>
  );
}

export default SkeletonCourseContent;
