import React from "react";
import WhyWeHereImg from "../../assets/images/WhyWeHere.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

function WhyWeHere() {
  return (
    <div className="container">
      <div className="why-we-here-container">
        <div className="section-title-container d-flex justify-content-center mb-5">
          <h2 className="section-title" id="home-whywehere-title">
            Why we here ?
          </h2>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6 col-sm-12">
            <img
              src={WhyWeHereImg}
              alt="why we here, what is so special"
              className="why-we-here-img"
              id="home-whywehere-image"
            />
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12">
            <p className="why-we-here-content" id="home-whywehere-content">
              <FontAwesomeIcon
                icon={faQuoteLeft}
                size={"3x"}
                style={{
                  color: "rgba(242, 242, 242, 0.49)",
                  marginRight: "10px",
                }}
              />
              We ensure every student here to get the best knowledge by their
              own experience. We are providing constant support to our clients.
              Grow your technical skills, become an expert in your field of
              knowledge and gain real-time experience by your own project work.
              We mainly focused on,
            </p>
            <ul className="why-we-here-list">
              <li>Job readiness</li>
              <li>Learner support</li>
              <li>Practical experience</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyWeHere;
