import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonCourseCard() {
  return (
    <SkeletonTheme color="#e6e6e6" highlightColor="rgb(255, 255, 255)">
      <article className="course-card list container mx-auto">
        <div className="thumbnail">
          <Skeleton height={`100%`} width={`100%`} />
        </div>

        <div className="course-details">
          <Skeleton height={25} width={200} />
          <span className="course-card-rating">
            <Skeleton height={25} width={80} />
          </span>

          <div className="more-info mt-3 d-flex justify-content-center flex-column">
            <Skeleton height={30} width={`100%`} />
          </div>
        </div>
      </article>
    </SkeletonTheme>
  );
}

export default SkeletonCourseCard;
