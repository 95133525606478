import React, { useEffect, useState } from "react";
import Skeleton from "./skeleton";
import "./videoPlayer.css";
import useReactEventTracker from "../../../hooks/react-ga";
function VideoPlayer(props) {
  const [videoSRC, setVideoSRC] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const gaEventTracker = useReactEventTracker("User-Interaction-Course-Details-Page");
  useEffect(() => {
    setIsLoading(true);
    if (props.data.video_url !== undefined) {
      setVideoSRC(props.data.video_url);
    }
    setIsLoading(false);
  }, [props]);
  return (
    <React.Fragment>
      {!isLoading && videoSRC.trim() !== "" ? (
        <div className="video-container">
          <iframe
            id="coursedetail-videoplayer"
            width="560"
            height="315"
            className="responsive-iframe"
            title="videoPlayer"
            allowFullScreen
            src={videoSRC}
            onClick={
              ()=>{
                gaEventTracker(`Video with URL: ${videoSRC} is getting played by the user`);
              }
            }
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      ) : isLoading ? (
        <Skeleton />
      ) : null}
    </React.Fragment>
  );
}
export default VideoPlayer;
