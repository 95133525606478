import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonGridCard() {
  return (
    <SkeletonTheme color="#e6e6e6" highlightColor="rgb(255, 255, 255)">
      <article style={{ width: "300px", height: "300px", margin: "10px" }}>
        <Skeleton height={200} width={`100%`} />
        <div className="course-details" style={{ marginTop: "15px" }}>
          <h4 className="course-title">
            <Skeleton height={25} width={200} />
          </h4>
          <p className="course-card-rating">
            <Skeleton height={25} width={80} />
          </p>
        </div>
        {/* <button className="view-course-btn">View</button> */}
      </article>
    </SkeletonTheme>
  );
}

export default SkeletonGridCard;
