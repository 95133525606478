import React, { Fragment, useState, useEffect, useContext } from "react";
import SkeletonEvent from "./eventSkeleton";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ModalView from "../../../components/modalView";
import { ModalContext } from "../../../App";
import service from "../../../service";
import "../../../components/popularCourses/popularCourses.css";
import "./upcomingEvents.css";
import useReactEventTracker from "../../../hooks/react-ga";

function UpcomingEvents(props) {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [batchesModal, setBatchesModal] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [eventData, setEventData] = useState({});
  const [responseModal, setResponseModal] = useContext(ModalContext);
  const gaEventTracker = useReactEventTracker("User-Interaction-Course-Details-Page");
  const openModal = () => {
    setModalShow(true);
    gaEventTracker(`Reach-Us button is clicked for the course ${props.coursename} by the user`);
    setResponseModal({ ...responseModal, reqDemoModal: true });
  };
  const closeModal = () => {
    setModalShow(false);
    setResponseModal({ ...responseModal, reqDemoModal: false });
  };

  useEffect(() => {
    if (responseModal.reqDemoModal === false && modalShow) {
      setModalShow(false);
    }
  }, [responseModal, modalShow]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (props.courseid !== "" && props.courseid !== undefined) {
      setCourseName(props.coursename);
      setIsLoading(true);

      async function getCourseData() {
        let data = await service.getUpcomingEvents(props.courseid, {
          signal: signal,
        });
        setUpcomingEvents(data);
        setIsLoading(false);
      }
      getCourseData();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [props]);
  const handlebatchesmodal = (event) => {
    setBatchesModal(true);
    setEventData(event);
  };
  const closeBatchesModal = () => {
    setBatchesModal(false);
  };
  const NextEvent = (props) => {
    let { style, onClick } = props;

    return (
      <button
        className="next-event-btn"
        onClick={onClick}
        style={{ ...style }}
        id="upevent-right-arrow-btn"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  };
  const PrevEvent = (props) => {
    let { style, onClick } = props;

    return (
      <button
        className="prev-event-btn"
        onClick={onClick}
        style={{ ...style }}
        id="upevent-left-arrow-btn"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  };

  const settings = {
    className: "w-100 slider variable-width upcoming-batch-container",
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <NextEvent />,
    prevArrow: <PrevEvent />,
    speed: 500,
    centerPadding: "20%",
    responsive: [
      {
        breakpoint: 99999, // These are the settings I want for everything but mobile
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991, // Mobile settings reapplied here
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };
  return (
    <Fragment>
      <h3 className="course-section-title">Upcoming Batches</h3>
      <div className="my-4 events-wrapper">
        <div className="d-flex flex-wrap">
          {isLoading
            ? [1, 2, 3, 4, 5, 6, 7].map((el) => <SkeletonEvent key={el} />)
            : null}
          {!isLoading && upcomingEvents.length > 0 ? (
            <div className="upcoming-course-wrapper">
              <div className="upcoming-course-container">
                <Slider {...settings}>
                  {upcomingEvents.map((event) => (
                    <div key={event.id} className="event-card-parent">
                      <article
                        id={event.id}
                        className="event-card"
                        onClick={() => handlebatchesmodal(event)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="rounded-event-time">
                            <span className="event-date">
                              {event.eventDate}
                            </span>
                            <span className="event-month">
                              {event.eventMonth}
                            </span>
                          </div>
                          <span className="event-time">{event.eventTime}</span>
                        </div>
                        {event.eventDiscount.enabled ? (
                          <div
                            className="event-discount-container"
                            title={`${event.eventDiscount.rate} Offer for this event`}
                          >
                            <p id="course-event-discount">
                              {event.eventDiscount.rate}%
                            </p>
                          </div>
                        ) : null}
                      </article>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="book-for-events mt-4">
        {!isLoading && upcomingEvents.length > 0 ? (
          <p>
            Time doesn't suit for you? No problem. Tell us which time suits
            you!!
            <button className="book-events-btn" onClick={openModal}>
              book
            </button>
          </p>
        ) : !isLoading ? (
          <div className="d-flex flex-column w-100">
            <h3>
              <span role="img" aria-labelledby="no-event">
                &#128198;
              </span>
              No Events !!
            </h3>

            <p>
              It's ok{" "}
              <span
                role="img"
                aria-labelledby="no-event"
                style={{ marginRight: "10px" }}
              >
                &#9996;
              </span>
              To know aboubt future upcoming events for this course,
              <button className="book-events-btn" onClick={openModal}>
                reach us
              </button>
            </p>
          </div>
        ) : null}
        <ModalView
          type="book"
          coursename={courseName}
          events={upcomingEvents}
          show={modalShow}
          onHide={closeModal}
        />
        <ModalView
          type="batches-modal"
          coursename={courseName}
          events={upcomingEvents}
          eventdata={eventData}
          show={batchesModal}
          onHide={closeBatchesModal}
        />
      </div>
    </Fragment>
  );
}
export default UpcomingEvents;
