import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StarRatings from "react-star-ratings";
import LinesEllipsis from "react-lines-ellipsis";
import SkeletonGridCard from "./loadingGridCard/skeleton";
import useReactEventTracker from "../../hooks/react-ga";
import "./gridCard.css";

export default function GridCard(props) {
  const [courseData, setCourseData] = useState({
    course_id: "",
    thumbnail_url: "",
    course_name: "",
    rating: 0,
    summary: "",
  });
  const [isIE, setIsIE] = useState(false);
  const [maxLine, setMaxLine] = useState(5);
  const gaEventTracker = useReactEventTracker("User-Interaction-Courses");
  let history = useHistory();
  const navigate = (id) => {
    //the id can be used later after implementing the course detail page.
    let url_coursename = courseData.course_name;
    url_coursename = url_coursename.replace(/\s+/g, "-").toLowerCase();
    history.push(`/courses/view/?courseName=${url_coursename}&id=${id}`);
    gaEventTracker(`View Course Button Clicked by the User for the course with name ${url_coursename}`);
  };
  function handleReSize() {
    setMaxLine(window.innerWidth <= 572 ? 2 : 5);
  }
  useEffect(() => {
    if (props.data !== undefined) {
      let { data } = props;

      setCourseData(data);
    }
  }, [props]);

  useEffect(() => {
    handleReSize();
    function checkisIE() {
      let ua = navigator.userAgent;
      /* MSIE used to detect old browsers and Trident used to newer ones*/
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

      return is_ie;
    }
    setIsIE(checkisIE);
  }, []);

  useEffect(() => {
    if (isIE) {
      window.addEventListener("resize", handleReSize);
      return () => {
        window.removeEventListener("resize", handleReSize);
      };
    }
  });
  let { course_id, thumbnail_url, course_name, rating, summary } = courseData;

  return (
    <>
      {course_id !== "" ? (
        <article
          className="course-grid-card"
          id="gridcard-navigate"
          onClick={() => navigate(course_id)}
          tabIndex="-1"
          title={course_name}
        >
          <div className="thumbnail">
            {thumbnail_url !== "" && thumbnail_url !== undefined ? (
              <img
                src={thumbnail_url}
                alt={course_name}
                id="gridcard-course-image"
              />
            ) : (
              <div className="gray-bg">{""}</div>
            )}
          </div>

          <div className="course-details">
            {isIE ? (
              <LinesEllipsis
                text={course_name}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="words"
                className="course-title"
                id="gridcard-course-title"
              />
            ) : (
              <h2 className="course-title" id="gridcard-course-title">
                {course_name}
              </h2>
            )}
            <span className="course-card-rating">
              <span id="gridcard-course-rating">
                {parseFloat(rating).toFixed(1)}
              </span>
              <StarRatings
                rating={parseFloat(rating)}
                starRatedColor="#ffca08"
                numberOfStars={5}
                name="rating"
                starDimension="15px"
                starSpacing={"2px"}
              />
            </span>

            <div className="more-info mt-3 d-flex justify-content-center flex-column">
              {isIE ? (
                <LinesEllipsis
                  text={summary}
                  maxLine={maxLine}
                  ellipsis="..."
                  trimRight
                  basedOn="words"
                  className="course-summary"
                  id="gridcard-course-summary"
                />
              ) : (
                <p className="course-summary" id="gridcard-course-summary">
                  {summary}
                </p>
              )}

              <button
                to="/courses"
                className="view-more-option"
                id="gridcard-course-viewcourse-btn"
                onClick={() => navigate(course_id)}
                tabIndex="-1"
              >
                view course
              </button>
            </div>
          </div>
        </article>
      ) : (
        <SkeletonGridCard />
      )}
    </>
  );
}
