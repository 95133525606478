import React, { Fragment, useEffect, useState } from "react";
import DiscountBadge from "../../../assets/images/discount_banner.png";
import DiscountPC from "../../../assets/images/discount_pc.png";
import "./discountBanner.css";

function DiscountBanner(props) {
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountRate, setDiscountRate] = useState("0%");
  useEffect(() => {
    if (props.discount_rate !== undefined) {
      // Need to modify once modifying the mongodb schema
      setShowDiscount(props.discount_rate.enabled);
      setDiscountRate(props.discount_rate.rate);
    }
  }, [props]);
  return (
    <Fragment>
      {showDiscount ? (
        <section className="discount-box">
          <div className="discount-badge-container">
            <img
              src={DiscountBadge}
              alt="discount badge"
              className="disc-badge"
            />
            <div className="d-flex flex-column align-items-center">
              <p className="discount-rate" id="discount-rate">
                {discountRate}
                <small>%</small>
              </p>
              <p
                style={{
                  top: "67px",
                  right: "31px",
                  fontSize: "1.5em",
                  textTransform: "uppercase",
                }}
              >
                Offer
              </p>
              <p
                style={{
                  top: "110px",
                  right: "15px",
                  fontSize: "1.2em",
                  maxWidth: "100px",
                  lineHeight: "27px",
                  textAlign: "center",
                  fontWeight: 400,
                }}
              >
                Special Discount
              </p>
            </div>
          </div>
          <img src={DiscountPC} alt="user computer" className="disc-pc" />
        </section>
      ) : null}
    </Fragment>
  );
}
export default DiscountBanner;
