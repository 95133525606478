import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./modesOfTraining.css";
import useReactEventTracker from "../../../hooks/react-ga";

function ModesOfTraining(props) {
  const gaEventTracker = useReactEventTracker("User-Interaction-Course-Details-Page");
  return (
    <Fragment>
      <h3 className="course-section-title">Mode of training</h3>
      <div className="mt-5 mode-card-wrapper">
        <div className="d-flex justify-content-evenly flex-wrapper">
          <div className="mode-card-container mx-2 mb-2">
            <div
              className="d-flex flex-column align-items-center"
              style={{ position: "relative" }}
            >
              <h3 className="training-mode-header" title="Self paced mode">
                SELF PACED
              </h3>
              <p className="training-mode-brief">
                We are offering 1 to 1 training. As per your flexibility with a
                flexible time schedule.
              </p>
            </div>
          </div>
          <div className="mode-card-container mx-2 mb-2">
            <div
              className="d-flex flex-column align-items-center"
              id="batchmode"
              style={{ position: "relative" }}
            >
              <h3 className="training-mode-header" title="batch mode">
                BATCH
              </h3>
              <p className="training-mode-brief">
                We will provide training as per your convenience to make it easy
                to enhance your technical stuff.
              </p>
              {/* <ul className="training-mode-brief">
                <li>Lorem ipsum dolormet consectetur.</li>
                <li>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                </li>
                <li>Facilis minima dolor quasi alias beatae repellendus.</li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="mode-training-doubts">
          <p className="mx-1">
            To get more clarification,{" "}
            <Link to="/contact-us" onClick={ ()=>{
                gaEventTracker(`Contact-Us button is clicked for the course ${props.coursename} by the user`);
              }}>
              Contact us{" "}
              <FontAwesomeIcon icon={faPhone} className="query-call-icon" />
            </Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
}
export default ModesOfTraining;
