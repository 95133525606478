import React, { useState, useEffect, Fragment } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
// Importing server functions
import GridCard from "../gridCard/gridCard";
import SkeletonGridCard from "../gridCard/loadingGridCard/skeleton";
import service from "../../service";
import "./popularCourses.css";

export default function PopularCourses() {
  const [courseData, setCourseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //   Here we will make a api call to get data from database.
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function getData() {
      let data = await service.getPopularCourses({ signal: signal });
      setCourseData(data);
      setIsLoading(false);
    }
    getData();
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const NextPopCourse = (props) => {
    let { style, onClick } = props;

    return (
      <button
        className="next-course-btn"
        onClick={onClick}
        style={{ ...style }}
        id="popular-courses-right-arrow-btn"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  };
  const PrevPopCourse = (props) => {
    let { style, onClick } = props;

    return (
      <button
        className="prev-course-btn"
        onClick={onClick}
        style={{ ...style }}
        id="popular-courses-left-arrow-btn"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  };
  const settings = {
    className: "w-100 slider variable-width pop-course-container",
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    // centerPadding: "60px",
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <NextPopCourse />,
    prevArrow: <PrevPopCourse />,
    speed: 500,
    centerPadding: "20%",
    responsive: [
      {
        breakpoint: 99999, // These are the settings I want for everything but mobile
        settings: {
          slidesToShow: 2,
          centerMode: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991, // Mobile settings reapplied here
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <Fragment>
      <Slider {...settings}>
        {!isLoading && courseData.length > 0
          ? courseData.map((course) => (
              <div
                key={course._id}
                title={course.course_name}
                id="popular-courses-card"
                tabIndex="-1"
              >
                <GridCard data={course} />
              </div>
            ))
          : [1, 2, 3, 4, 5, 6, 7, 8].map((el) => <SkeletonGridCard key={el} />)}
      </Slider>
    </Fragment>
  );
}
