import React, { Fragment, useEffect, useState } from "react";
import GridCard from "../../../components/gridCard/gridCard";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import SkeletonGridCard from "../../../components/gridCard/loadingGridCard/skeleton";
import "../../../components/popularCourses/popularCourses.css";
import service from "../../../service";
import "./relatedCourses.css";

export default function RelatedCourses(props) {
  const [RelatedCourses, setRelatedCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function getRelatedCourses() {
      let relatedData = await service.getRelatedCourseDetails(props.data.value);
      setIsLoading(true);
      setRelatedCourses(relatedData);
    }
    if (props.data !== undefined) {
      getRelatedCourses();
    }
    return () => {};
  }, [props.data]);
  const NextRelatedCourse = (props) => {
    let { style, onClick } = props;

    return (
      <button
        className="next-course-btn"
        onClick={onClick}
        style={{ ...style }}
        id="relative-courses-right-arrow-btn"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  };
  const PrevRelatedCourse = (props) => {
    let { style, onClick } = props;

    return (
      <button
        className="prev-course-btn"
        onClick={onClick}
        style={{ ...style }}
        id="relative-courses-left-arrow-btn"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  };
  const settings = {
    className: "w-100 slider variable-width related-course-carousel",
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: true,
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <NextRelatedCourse />,
    prevArrow: <PrevRelatedCourse />,
    speed: 500,
    centerPadding: "10%",
    responsive: [
      {
        breakpoint: 99999, // These are the settings I want for everything but mobile
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991, // Mobile settings reapplied here
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
          centerPadding: "10px",
        },
      },
    ],
  };

  return (
    <Fragment>
      {RelatedCourses.report !== undefined &&
      RelatedCourses.report === "No Content" ? null : (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div
            id="coursedetail-related-courses-section "
            className="related-courses-section"
          >
            <h3 className="course-section-title">Related Courses</h3>
            <div className="related-course-wrapper">
              <div className="my-4 related-course-container">
                <Slider {...settings}>
                  {isLoading && RelatedCourses.length > 0
                    ? RelatedCourses.map((course) => (
                        <div key={course._id} title={course.course_name}>
                          <GridCard data={course} />
                        </div>
                      ))
                    : [1, 2, 3, 4, 5, 6, 7, 8].map((el) => (
                        <SkeletonGridCard key={el} />
                      ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
