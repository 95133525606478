// const url = "http://ec2-15-206-73-56.ap-south-1.compute.amazonaws.com:5000";
// const url = "https://orionappv1.herokuapp.com";
const url = "https://orion-server.onrender.com";
// const url = "http://localhost:8081";

const service = {
  getCoursesPills: async (cb) => {
    var result = [];
    await fetch(url + "/course-categories", cb, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        result = res;
      });

    return result;
  },
  getCourseDetails: async (courseId) => {
    let courseData;
    await fetch(url + "/course-data/" + courseId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        courseData = res;
      });
    return courseData;
  },
  getUpcomingEvents: async (courseId, cb) => {
    let events = [];
    function getEventTime(date) {
      if (date.trim() !== "") {
        let timeArr = date.split(":");
        let hours = timeArr[0];
        let minutes = timeArr[1];
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? "0" + hours : hours;
        minutes = parseInt(minutes) < 10 ? "0" + parseInt(minutes) : minutes;
        return `${hours}:${minutes} ${ampm}`;
      }
      return;
    }
    await fetch(url + `/events/${courseId}`, cb, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (res) => {
          if (res.length > 0) {
            let monthArr = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            events = res.map((event) => {
              let modified = {};
              let thedate = new Date(event.event_on.date);
              let eventDate = thedate.getDate();
              eventDate =
                parseInt(eventDate) < 10
                  ? "0" + parseInt(eventDate)
                  : eventDate;
              modified.eventDate = eventDate;
              modified.eventDay = thedate.getDay();
              modified.id = event._id;
              modified.eventMonth = monthArr[thedate.getMonth()];
              modified.eventTime = getEventTime(event.event_on.time);
              modified.eventDiscount = event.discount;
              return modified;
            });
          } else {
            events = [];
          }
        },
        (error) => {
          console.error("onRejected function called: " + error);
          if (error.message === "Unauthorized") {
            events = { status: 401, report: error.message };
          } else {
            events = { status: 500, report: "internel error" };
          }
        }
      );
    return events;
  },
  getAllCourses: async (cb) => {
    var result = [];
    await fetch(url + "/all-courses", cb, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        result = res;
      });
    return result;
  },
  getRelatedCourseDetails: async (course_category) => {
    let relatedCourses;
    await fetch(url + "/relatedcourse/" + course_category, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        relatedCourses = res;
      });
    return relatedCourses;
  },
  getPopularCourses: async (cb) => {
    var result = [];
    await fetch(url + "/popularCourses", cb, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return (result = res);
      });
    return result;
  },
  queryFormSubmit: async (userData) => {
    var result = {};
    // var status="";
    await fetch(url + "/queryForm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json();
        }
      }, {})
      .then(
        (res) => {
          result = { status: 200, data: res };
        },
        (error) => {
          console.error("onRejected function called: " + error.message);
          result = { status: 500, report: "internel error" };
        }
      );
    return result;
  },
  getTestimonials: async (cb) => {
    var result = [];
    await fetch(url + "/testimonials", cb, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return (result = res);
      });
    return result;
  },
};

export default service;
