import "./App.css";
import React, { createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Media from "react-media";
import Navbar from "./components/navBar/navBar";
import Footer from "./components/footer/footer";
import Home from "./views/home/homeIndex";
import Courses from "./views/courses/courseIndex";
import Course from "./views/courseDetail/courseDetailIndex";
import ContactUs from "./views/contactus/contactUsIndex";
import NotFound from "./views/notFound/NotFound";
import ScrollTopButton from "./components/scrollTopButton/scrollTopButton";
import BecomeATuTor from "./components/beTutor//BeATuTor";
import ModalPopUp from "./components/modalPopUp";
import ThankYouModal from "./components/thankyoumodal/thankYouModal";
import ErrorModal from "./components/errorModal/errorModal";
import ReactGA from "react-ga";

export const ModalContext = createContext();

export const ModalContextProvider = (props) => {
  const [responseModal, setResponseModal] = useState({
    reqDemoModal: false,
    beTutorModal: false,
    successModal: false,
    errorModal: false,
    queryModal: false,
    termsModal: false,
    privacyModal: false,
  });
  return (
    <ModalContext.Provider value={[responseModal, setResponseModal]}>
      {props.children}
    </ModalContext.Provider>
  );
};

function PageView(){
  ReactGA.initialize('UA-185758722-1');
  ReactGA.pageview(window.location.pathname);
}

function App(props) {
  return (
    <ModalContextProvider>
      <Router>
        <div className="App">
          <Media queries={{ large: { maxWidth: 1200 } }}>
            {(matches) => (matches.large ? <ModalPopUp /> : null)}
          </Media>
          <BecomeATuTor />
          <ScrollTopButton />
          <Navbar />
          <main className="main-section">
            <section className="content">
              <Switch>
                <Route exact path="/" render={() => {
                  PageView();
                  return <Redirect to="/home" />;
                } }/>;
                <Route path="/home" render={(props) => {
                  PageView();
                  return <Home {...props} />;
                }} />
                <Route
                  exact
                  path="/courses"
                  render={(props) => {
                    PageView();
                    return <Courses {...props} />;
                  }}
                />
                <Route
                  path="/courses/view"
                  render={(props) => {
                    PageView();
                    return <Course {...props} />;
                  }}
                />
                <Route
                  path="/contact-us"
                  render={(props) => {
                    PageView();
                    return <ContactUs {...props} />;
                  }}
                />
                <Route
                  path="/page-not-found"
                  render={(props) => {
                    PageView();
                    return <NotFound {...props} />;
                  }}
                />
                <Route
                  path="/*"
                  render={() => {
                    PageView();
                    return <Redirect to="/page-not-found" />;
                  }}
                />
              </Switch>
            </section>
            <ThankYouModal />
            <ErrorModal />
            <Footer {...props} />
          </main>
        </div>
      </Router>
    </ModalContextProvider>
  );
}

export default App;
