import React, { useState, Fragment, useEffect } from "react";
import SkeletonCourseContent from "./contentSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./courseContent.css";

function CourseContent(props) {
  let [collapseStatus, setCollapseStatus] = useState([]);
  let [courseContent, setCourseContent] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandAllValue, setAllvalue] = useState("Expand All");
  useEffect(() => {
    if (props.data.course_content !== undefined) {
      setCourseContent(props.data.course_content);
      let contentArr = [];
      for (let i = 0; i < props.data.course_content.length; i++) {
        contentArr.push(false);
      }
      setCollapseStatus(contentArr);
    }
  }, [props]);
  const handleCollapse = (index) => {
    let newArr = [...collapseStatus];
    newArr[index] = !newArr[index];
    setCollapseStatus(newArr);
    if (newArr.every((val) => val === true)) {
      setIsExpanded(true);
      setAllvalue("Collapse All");
    } else if (newArr.every((val) => val === false)) {
      setIsExpanded(false);
      setAllvalue("Expand All");
    }
  };
  const handleClick = () => {
    let expandArr = [];
    if (expandAllValue === "Expand All") {
      setIsExpanded(true);
      for (let i = 0; i < courseContent.length; i++) {
        expandArr.push(true);
      }
      setCollapseStatus(expandArr);
      setAllvalue("Collapse All");
    } else {
      setIsExpanded(false);
      for (let i = 0; i < courseContent.length; i++) {
        expandArr.push(false);
      }
      setCollapseStatus(expandArr);
      setAllvalue("Expand All");
    }
  };
  return (
    <React.Fragment>
      <div className="panel-group" id="course-content">
        <div className="toDisplayinline">
          <div className="course-section-title">Course Content</div>
          <button
            className="expand-all"
            onClick={handleClick}
            data-toggle="collapse"
            data-parent="#course-content"
            data-target=".pack-collapse"
          >
            {expandAllValue}
          </button>
        </div>

        {courseContent.length > 0 ? (
          courseContent.map((data, index) => {
            return (
              <CollapseCard
                key={index}
                data={data}
                index={index}
                isExpanded={isExpanded}
                handleCollapse={handleCollapse}
              />
            );
          })
        ) : (
          <Fragment>
            {[1, 2, 3, 4, 5].map((el) => (
              <SkeletonCourseContent key={el} />
            ))}
          </Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
function CollapseCard(props) {
  let index = props.index;
  let data = props.data;
  let indexvalue = parseInt(index) + 1;
  let linkString = "link";
  let link = "#";
  let hreflink = link.concat(linkString, indexvalue);
  let linkId = linkString.concat(indexvalue);
  const handleClickCollapse = (index) => {
    props.handleCollapse(index);
  };

  return (
    <React.Fragment>
      <div
        className="course-content-card"
        index={index + 1}
        data-toggle="collapse"
        data-parent="#course-content"
        aria-expanded={props.isExpanded}
        href={hreflink}
        onClick={() => handleClickCollapse(index)}
      >
        <div className="panel">
          <div className="panel-heading">
            <div className="d-flex justify-content-between">
              <div className="session-title">
                {Number(indexvalue) + ". " + data.title}
              </div>
              <div className="arrow-icons">
                <span className="angle-up-icon">
                  <FontAwesomeIcon icon={faAngleUp} />
                </span>
                <span className="angle-down-icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id={linkId}
        className={
          props.isExpanded
            ? "panel-collapse collapse show"
            : "panel-collapse collapse pack-collapse"
        }
      >
        <ol className="sub-header-container" type="1">
          {data.sub_header.map((subdata, index) => {
            let IndexNum = String(indexvalue) + "." + (index + 1);
            return (
              <li className="sub-header" key={index} step={index + 1}>
                <div className="mb-2">{IndexNum + " - " + subdata.content}</div>
                {subdata.children !== undefined && subdata.children.length > 0 && (
                  <ol className="child-header-container py-2">
                    {subdata.children.map((child, index) => {
                      let ChildIndex = String(IndexNum) + "." + (index + 1);
                      return (
                        <li className="py-2" key={index}>
                          {ChildIndex + " - " + child.content}
                        </li>
                      );
                    })}
                  </ol>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    </React.Fragment>
  );
}
export default CourseContent;
