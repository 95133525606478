import React from "react";
import QueryImg from "../../../assets/images/haveAQuery.png";
function QueryImage() {
  return (
    <img
      src={QueryImg}
      className="query-theme-img"
      alt="have any query"
      id="home-queryform-image"
    />
  );
}
export default QueryImage;
