import LogoImg from "../../assets/images/Rajalas_Academy_Logo_BW_Transparent.png";
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ModalContext } from "../../App";
import PolicyModal from "../policiesmodal/privacyPolicy";
import TermsAndConditions from "../policiesmodal/termsAndConditions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./footerStyles.css";

function Footer() {
  const [nextPage, setNextPage] = useState({
    url: "",
    pathName: "",
  });
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [termsPolicy, setTermsPolicy] = useState(false);
  // eslint-disable-next-line
  const [responseModal, setResponseModal] = useContext(ModalContext);
  let { pathname } = useLocation();

  let tempResponseContext = { ...responseModal };
  tempResponseContext.termsModal = false;
  tempResponseContext.privacyModal = false;

  useEffect(() => {
    let nextPageData = {
      url: "",
      pathName: "",
    };
    if (pathname === "/home") {
      nextPageData.url = "/courses";
      nextPageData.pathName = "Courses";
    } else if (pathname === "/courses" || pathname.includes("/courses")) {
      nextPageData.url = "/contact-us";
      nextPageData.pathName = "Contact us";
    } else {
      nextPageData.url = "/home";
      nextPageData.pathName = "Home";
    }
    setNextPage(nextPageData);
  }, [pathname]);
  const openPrivacyModal = () => {
    setPrivacyPolicy(true);
    tempResponseContext.privacyModal = true;
    setResponseModal(tempResponseContext);
  };
  const closePrivacyPolicy = () => {
    setPrivacyPolicy(false);
    tempResponseContext.privacyModal = false;
    setResponseModal(tempResponseContext);
  };
  const openTermsModal = () => {
    setTermsPolicy(true);
    tempResponseContext.termsModal = true;
    setResponseModal(tempResponseContext);
  };
  const closeTermsModal = () => {
    setTermsPolicy(false);
    tempResponseContext.termsModal = false;
    setResponseModal(tempResponseContext);
  };

  return (
    <>
      <footer className="foot-section">
        <div className="foot-flex-container container">
          <div className="logo-container">
            <a href="/home" className="companyName">
              <img
                src={LogoImg}
                alt="Rajala's Tech Academy"
                className="companyLogo"
              />
            </a>
          </div>
          <div className="foot-nav-container">
            <div className="explore-container">
              <h5 className="foot-sub-header" id="footer-explore">
                Explore
              </h5>
              <ul className="footer-nav-links">
                <li className="footer-nav-item">
                  <Link
                    to="/home"
                    className="foot-nav-link"
                    id="footer-explore-home"
                  >
                    Home
                  </Link>
                </li>
                <li className="footer-nav-item">
                  <Link
                    to="/courses"
                    className="foot-nav-link"
                    id="footer-explore-courses"
                  >
                    Courses
                  </Link>
                </li>
                <li className="footer-nav-item">
                  <Link
                    to="/contact-us"
                    className="foot-nav-link"
                    id="footer-explore-contactus"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="legal-form-container">
              <h5 className="foot-sub-header" id="footer-legal">
                Legal
              </h5>
              <ul className="footer-nav-links">
                <li className="footer-nav-item">
                  <button
                    className="button-nav-link"
                    id="footer-legal-terms"
                    onClick={openTermsModal}
                  >
                    Terms
                  </button>
                </li>
                <li className="footer-nav-item">
                  <button
                    className="button-nav-link"
                    id="footer-legal-privacy-policy"
                    onClick={openPrivacyModal}
                  >
                    Privacy
                  </button>
                </li>
              </ul>
            </div>
            <div className="social-links-container">
              <h5 className="foot-sub-header" id="footer-reach-us">
                Reach us
              </h5>
              <ul className="footer-nav-links d-flex">
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-facebook"
                >
                  <a
                    href="https://www.facebook.com/Rajalas-Tech-Academy-101822064992885/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="foot-social-link"
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} size={"2x"} />
                  </a>
                </li>
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-instagram"
                >
                  <a
                    href="https://www.instagram.com/rajalas_tech_academy/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="foot-social-link"
                  >
                    <FontAwesomeIcon icon={faInstagramSquare} />
                  </a>
                </li>
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-youtube"
                >
                  <a
                    href="https://www.youtube.com/channel/UCmJhfku5WFCD4oMB5FZFAqg?view_as=subscriber"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="foot-social-link"
                  >
                    <FontAwesomeIcon icon={faYoutubeSquare} size={"2x"} />
                  </a>
                </li>
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-twitter"
                >
                  <a
                    href="https://twitter.com/RajalaTech"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="foot-social-link"
                  >
                    <FontAwesomeIcon icon={faTwitterSquare} />
                  </a>
                </li>
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-linkedin"
                >
                  <a
                    href="https://www.linkedin.com/in/rajala-s-tech-academy-1621091b6/"
                    className="foot-social-link"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
              <ul className="footer-nav-links d-flex flex-column">
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-mobile"
                >
                  <a
                    href="tel:7729852396"
                    rel="noopener noreferrer"
                    className="foot-social-link"
                  >
                    ‎+91 7729852396
                  </a>
                  <a
                    href="tel:9739231289"
                    rel="noopener noreferrer"
                    className="foot-social-link"
                  >
                    ‎+91 7075392509
                  </a>
                </li>
                <li
                  className="footer-nav-item social"
                  id="footer-reach-us-mail"
                >
                  <a
                    href="mailto:info@rajalas-academy.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="foot-social-link"
                  >
                    info@rajalas-academy.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="next-page-section">
            <p className="next-page-url" id="footer-next">
              Next :
              <Link to={nextPage.url} id="footer-next-page-option">
                {nextPage.pathName}
              </Link>
            </p>
          </div>
        </div>
        <div className="bottom-info px-4">
          <h6 className="attribute">
            Images credit:
            <a className="attribute-link" href="https://www.freepik.com/">
              {" "}
              Freepik.com
            </a>
          </h6>
        </div>
        <PolicyModal show={privacyPolicy} onHide={closePrivacyPolicy} />
        <TermsAndConditions show={termsPolicy} onHide={closeTermsModal} />
      </footer>
    </>
  );
}

export default Footer;
