import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function SkeletonEvent() {
  return (
    <SkeletonTheme color="#f2f2f2" highlightColor="rgb(255, 255, 255)">
      <article className="event-card">
        <div className="d-flex align-items-center">
          <Skeleton circle={true} height={60} width={60} />
          <span>&nbsp;&nbsp;</span>
          <Skeleton height={30} width={100} />
        </div>
      </article>
    </SkeletonTheme>
  );
}

export default SkeletonEvent;
