import React, { useState, useEffect } from "react";
import SkeletonTestimonial from "./loadingTestimonial/skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons";
import service from "../../service";
import "./testimonialStyles.css";

const mockTestimonials = [
  {
    name: {
      value: "Deepa R",
      label: "Deepa R",
    },
    message:
      "The trainers are very kind and well experienced. Learnt many things by doing real time project and had a good hands-on experience.",
    title: "Real time experience",
    designation: "Tech Architect",
  },
  {
    name: {
      value: "Rajesh Kumar",
      label: "Rajesh Kumar",
    },

    message:
      "The trainers are very kind and well experienced. learnt many things by doing real time project and handson",
    title: "Good and Great",
    designation: "Senior System Engineer",
  },
  {
    name: {
      value: "Anbudhasan P.A",
      label: "Anbudhasan P.A",
    },
    message:
      "The trainers are very kind and well experienced. learnt many things by doing real time project and handson",
    title: "Extraordinary",
    designation: "DevOps Developer",
  },
];

function Testimonials() {
  const [testimonialData, setTestimonialData] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function getData() {
      let data = await service.getTestimonials({ signal: signal });
      if (data.length > 0) {
        setTestimonialData(data);
      } else {
        setTestimonialData(mockTestimonials);
      }
    }
    getData();
    return function cleanup() {
      abortController.abort();
    };
  }, []);
  let indicators_childern = (
    <li data-target="#testimonials" className="active"></li>
  );
  if (testimonialData.length !== 0) {
    indicators_childern = testimonialData.map((child, index) => {
      return (
        <li
          data-target="#testimonials"
          data-slide-to={index}
          className={index === 0 ? "active" : null}
          key={index}
        ></li>
      );
    });
  }
  let carousel_item = <SkeletonTestimonial />;

  if (testimonialData.length !== 0) {
    carousel_item = testimonialData.map((child, index) => {
      let splited = child.name.value.split(" ");
      var clientShortName = "";
      if (splited.length > 0) {
        let i = 0;
        while (i < 2) {
          if (splited[i] !== undefined) {
            clientShortName += splited[i].slice(0, 1);
          }
          i++;
        }
      }
      return (
        <React.Fragment key={index}>
          <div
            className={index === 0 ? "carousel-item active" : "carousel-item"}
          >
            <Testimonial
              clientShortName={clientShortName}
              clientName={child.name.value}
              feedback={child.message}
              title={child.title}
              designation={child.designation}
            />
          </div>
        </React.Fragment>
      );
    });
  }
  return (
    <div style={{ position: "relative" }}>
      <div
        id="testimonials"
        className="carousel slide"
        data-ride="carousel"
        data-interval="4000"
        data-pause="hover"
      >
        <ul className="carousel-indicators">{indicators_childern}</ul>
        <div className="carousel-inner">
          <h2 className="testimonials-header">What they are all saying..!</h2>
          {carousel_item}
        </div>

        <a
          className="carousel-control-prev"
          href="#testimonials"
          data-slide="prev"
        >
          <FontAwesomeIcon icon={faChevronLeft} size={"2x"} />
        </a>
        <a
          className="carousel-control-next"
          href="#testimonials"
          data-slide="next"
        >
          <FontAwesomeIcon icon={faChevronRight} size={"2x"} />
        </a>
      </div>
    </div>
  );
}

function Testimonial({
  clientShortName,
  clientName,
  feedback,
  title,
  designation,
}) {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="testimonial-container px-2">
        <div className="client-picture-section">
          <div className="client-profile-rounded">
            <p className="client-shortname">{clientShortName}</p>
          </div>
        </div>
        <div className="testimonial-content">
          <h2 className="feedback-title">{title}</h2>
          <FontAwesomeIcon
            icon={faQuoteLeft}
            size="2x"
            className="quote-left"
          />
          <p className="client-feedback">{feedback}</p>
          <h2 className="client-name">{clientName}</h2>
          <small className="client-designation">{designation}</small>
        </div>
      </div>
    </div>
  );
}
export default Testimonials;
